import posthogJS from "posthog-js";

import { isLocalhost } from "@/helpers/utils";

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;

/**
 * Re-exposing Posthog as a proxy that will no-op if the API key isn't set.
 */
export const posthog = POSTHOG_API_KEY ? posthogJS : undefined;

export const initPosthog = (): void => {
  if (POSTHOG_API_KEY) {
    posthogJS.init(POSTHOG_API_KEY, {
      /** Reverse proxy to https://eu.posthog.com */
      api_host: isLocalhost ? "https://eu.posthog.com" : "/events",
      autocapture: false,
      /**
       * Don't "double track" pageviews on the initial load, as we're doing it there
       * See {@link file://./../hooks/usePostHogPageTrack.ts}
       */
      capture_pageview: false,
      disable_session_recording: true,
      session_recording: {
        recordCrossOriginIframes: true,
      },
      loaded: (posthog) => {
        if (import.meta.env.VITE_ENVIRONMENT === "production") {
          posthog.startSessionRecording();
        }
      },
    });
  }
};
