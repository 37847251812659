import { MODEL_NAMES } from "inexone-common/constants";
import type { ExpertProfileAttributes } from "inexone-common/models/ExpertProfile";
import type { CalendarEvent } from "inexone-common/types/Calendar";
import { expertHasFutureAvailabilities } from "inexone-common/utils/expertHelpers";

import ParseObject from "./utils/ParseObject";

export default class ExpertProfile extends ParseObject<ExpertProfileAttributes>(
  MODEL_NAMES.EXPERT_PROFILE,
) {
  /**
   * Tracking metadata to analyse user behaviour based on expert availability
   */
  static trackAvailabilityBehaviour(
    availability: CalendarEvent[],
    isAvailable: boolean,
  ): {
    hasAvailabilities: boolean;
    isAvailable: boolean;
  } {
    return {
      hasAvailabilities: expertHasFutureAvailabilities(availability),
      isAvailable,
    };
  }
}
