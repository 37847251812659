/**
 * Retry a promise a given number of times before failing
 */
export function retry<T>(
  fn: () => Promise<T>,
  fileName: string,
  retriesLeft = 3,
  interval = 500,
): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 0) {
            error.message = `Loading of the resource "${fileName}" failed\n\n${error.message}`;
            // @ts-expect-error (filename property does not exist on errors)
            error.fileName = fileName;
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, fileName, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
