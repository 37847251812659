import { lazy, Suspense } from "react";

import { retry } from "@/helpers/asyncLoad";

import type { Props } from "./ExceptionComponent";

const ExceptionComponent = lazy(() =>
  retry(() => import("./ExceptionComponent"), "ExceptionComponent"),
);

const Exception = (props: Props): JSX.Element => {
  return (
    <Suspense>
      <ExceptionComponent {...props} />
    </Suspense>
  );
};

export default Exception;
