import moment from "moment-timezone";

import { MOMENT_FORMATS } from "inexone-common/constants";

export const isCurrentMonth = (date: Date) => {
  const now = new Date();
  return (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const toMonthYear = (date: string) => {
  return moment(date).format(MOMENT_FORMATS.MONTH_YEAR);
};

export const getDateTimeRange = (startDate: Date, endDate: Date) => {
  const startDateMoment = moment(startDate);
  return `${startDateMoment.format(MOMENT_FORMATS.TIME)}-${moment(endDate).format(MOMENT_FORMATS.TIME)}, ${startDateMoment.format(MOMENT_FORMATS.DATE)}`;
};

export const getDateRangeString = ({
  startDate,
  endDate,
  format = MOMENT_FORMATS.DATE,
}: {
  startDate: Date;
  endDate: Date | string;
  format?: string;
}): string => {
  const endDateString =
    typeof endDate === "string" ? endDate : moment(endDate).format(format);

  return `${moment(startDate).format(format)} - ${endDateString}`;
};
