import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useIntercom, type IntercomContextValues } from "react-use-intercom";

import type { Workspace } from "inexone-common/types/schemas/workspaces";

import { dateToSeconds } from "@/helpers/utils";
import useAuth from "@/hooks/useAuth";
import type { _User as User } from "@/shared/models";
import { posthog } from "@/shared/posthog";
import customizations from "@/shared/customizations";

/**
 * Sending data to intercom
 */
export const sendUserInitialized = (
  user: User,
  currentWorkspace: Workspace,
  boot: IntercomContextValues["boot"],
): void => {
  const trackedUserData = {
    firstName: user.get("firstName"),
    lastName: user.get("lastName"),
    email: user.getEmail(),
    phone: user.getPhone(),
    roleName: currentWorkspace.membership.type,
    signedUpAt: dateToSeconds(user.createdAt),
    organization: currentWorkspace.organization.name,
    organizationDomain: currentWorkspace.organization.domains[0],
    organizationRole: currentWorkspace.membership.type,
    organizationId: currentWorkspace.organization.id,
  };

  window.dataLayer.push({
    userId: user.id,
    event: "userDataGathered",
    ...trackedUserData,
  });
  posthog?.identify(user.id, trackedUserData);

  if (customizations.enableIntercomSupport) {
    boot({
      email: user.getEmail(),
      userHash: user.getIntercomHash(),
      /**
       * user_id must be provided here, because we're
       * identifying users through their ID, not their email
       */
      userId: user.id,
      hideDefaultLauncher: true,
    });
  }
};

export const sendVisitorInitialized = (
  boot: IntercomContextValues["boot"],
): void => {
  boot();
};

export const sendUserLoggedOut = (
  shutdown: IntercomContextValues["shutdown"],
): void => {
  shutdown();
};

export const sendImpersonatorInitialized = (
  hardShutdown: IntercomContextValues["hardShutdown"],
): void => {
  hardShutdown();
  // Don't track user on posthog when impersonating
  posthog?.opt_out_capturing();
};

const initUserAnalytics = (
  user: User,
  currentWorkspace: Workspace,
  isImpersonator: boolean,
  hardShutdown: IntercomContextValues["hardShutdown"],
  boot: IntercomContextValues["boot"],
) => {
  Sentry.configureScope((scope) =>
    scope.setUser({
      email: user.getEmail(),
      id: user.id,
    }),
  );

  if (isImpersonator) {
    // Don't track user on intercom when impersonating
    sendImpersonatorInitialized(hardShutdown);
  } else {
    sendUserInitialized(user, currentWorkspace, boot);
  }
};

const clearUserAnalytics = (
  shutdown: IntercomContextValues["shutdown"],
  boot: IntercomContextValues["boot"],
) => {
  sendUserLoggedOut(shutdown);
  Sentry.configureScope((scope) => scope.setUser(null));
  sendVisitorInitialized(boot);
};

export default function useAnalyticsAuthListener(): void {
  const { boot, shutdown, hardShutdown } = useIntercom();
  useEffect(
    () =>
      useAuth.subscribe(
        (state) => ({
          user: state.currentUser,
          currentWorkspace: state.currentWorkspace,
          authorityMatches: state.authorityMatches,
        }),
        (state, prevState) => {
          const {
            user: currentUser,
            currentWorkspace,
            authorityMatches,
          } = state;
          const { user: previousUser } = prevState;

          // Note: These checks on id are based on the AuthStore default value
          //       on currentUser, which is User.fromObject({}). A check for an undefined
          //       value is invalid since there is an object by default
          if (currentUser.id && !previousUser.id) {
            return initUserAnalytics(
              currentUser,
              currentWorkspace,
              authorityMatches(["impersonator"]),
              hardShutdown,
              boot,
            );
          }

          if (!currentUser.id) {
            return clearUserAnalytics(shutdown, boot);
          }
        },
      ),
    [boot, hardShutdown, shutdown],
  );
}
