import { lazy, useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { PATHS } from "inexone-common/constants";

import { retry } from "@/helpers/asyncLoad";
import useAnalyticsAuthListener from "@/hooks/useAnalyticsAuthListener";
import useAuth from "@/hooks/useAuth";
import usePostHogPageTrack from "@/hooks/usePostHogPageTrack";
import useReferralCookie from "@/hooks/useReferralCookie";
import { ScreenSizeProvider } from "@/shared/contexts/ScreenSizeContext";
import { queryClient } from "@/shared/queryClient";

import PageTitle from "@/components/Page/PageTitle";
import CenteredSpinner from "@/components/Progress/CenteredSpinner";
import ChildRoutes, {
  type SwitchChild,
} from "@/components/Routing/ChildRoutes";
import { TooltipProvider } from "@/components/ui/tooltip";

// pop these into functions as imports cannot be supplied with
// variables
const getUserPage = () => import("@/pages/root-pages/UserPage/UserPage");
const getDefaultPage = () =>
  import("@/pages/root-pages/DefaultPage/DefaultPage");
const getVoicePage = () => import("@/pages/root-pages/VoicePage/VoicePage");

type ImportReturn = {
  default: React.ComponentType;
};

const initialPageLoader = (
  getPage: () => Promise<ImportReturn>,
  pageName: string,
) => {
  return lazy(() => retry(getPage, pageName));
};

const childRoutes: SwitchChild[] = [
  {
    path: [
      PATHS.USER.ROOT,
      PATHS.SIGNED_IN_USER.ROOT,
      PATHS.VERIFY_EMAIL_COMPLETE,
    ],
    Component: initialPageLoader(getUserPage, "pages/UserPage"),
  },
  {
    path: [PATHS.CALLS],
    Component: initialPageLoader(getVoicePage, "pages/VoicePage"),
  },
  {
    path: PATHS.DEFAULT.ROOT,
    Component: initialPageLoader(getDefaultPage, "pages/DefaultPage"),
  },
];

const ChildRouteSwitch = () => {
  const initialized = useAuth((state) => state.initialized);

  useEffect(() => {
    void useAuth.getState().initAuthState();
  }, []);

  if (!initialized) {
    return <CenteredSpinner />;
  }

  /**
   * Only render child routes after initial redirectOnAuthState is completed,
   * to avoid possible race conditions with redirects in ChildRoutes.
   */
  return <ChildRoutes routes={childRoutes} />;
};

const QueryApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChildRouteSwitch />
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </QueryClientProvider>
  );
};

const Main = (): JSX.Element => {
  useAnalyticsAuthListener();
  usePostHogPageTrack();
  useReferralCookie();

  return (
    <ScreenSizeProvider>
      <TooltipProvider>
        <PageTitle titles={["Loading..."]} />
        <QueryApp />
      </TooltipProvider>
    </ScreenSizeProvider>
  );
};

export default Main;
