import { CONFERENCE_TYPE, USER_ROLE_NAMES } from "inexone-common/constants";
import { ParticipantsStatus } from "inexone-common/constants/callLog";
import { EventType } from "inexone-common/models/CallLog";

import type { CallLog } from "shared/models";

export const getParticipantsInRoom = (callLogs: CallLog[]): CallLog[] => {
  if (callLogs.length === 0) {
    return [];
  }

  const sortedCallLogs = callLogs.sort(
    (a, b) => a.get("date").getTime() - b.get("date").getTime(),
  );

  const logsLength = sortedCallLogs.length;
  const lastRoomSid = sortedCallLogs[logsLength - 1].get("roomId");

  const lastRoomLogs = sortedCallLogs.filter(
    (log) =>
      log.get("roomId") === lastRoomSid &&
      log.get("participantId") &&
      (log.get("eventType") === EventType.ParticipantJoined ||
        log.get("eventType") === EventType.ParticipantLeft),
  );

  const lastParticipantLogs: Record<string, CallLog> = {};

  // get last call log entry for each participant
  lastRoomLogs.forEach((callLog) => {
    const participantId = callLog.get("participantId");

    if (!participantId) return;

    lastParticipantLogs[participantId] = callLog;
  });

  const participantJoinLogs = Object.values(lastParticipantLogs).filter(
    (callLog) => callLog.get("eventType") === EventType.ParticipantJoined,
  );

  return participantJoinLogs;
};

export const getCallParticipantStatus = (
  participantJoinLogs: CallLog[],
): ParticipantsStatus => {
  if (participantJoinLogs.length === 0) {
    return ParticipantsStatus.ALL_MISSING;
  }

  const meetingType = participantJoinLogs[0].get("meetingType");

  if (meetingType === CONFERENCE_TYPE.ZOOM) {
    return participantJoinLogs.length < 2
      ? ParticipantsStatus.UNKNOWN_MISSING
      : ParticipantsStatus.ALL_JOINED;
  }

  const hasExpert = participantJoinLogs.some(
    (logRow) => logRow.get("participantRole") === USER_ROLE_NAMES.CONTRACTOR,
  );

  const hasCustomer = participantJoinLogs.some(
    (logRow) => logRow.get("participantRole") === USER_ROLE_NAMES.CUSTOMER,
  );

  if (!hasExpert && !hasCustomer) return ParticipantsStatus.ALL_MISSING;
  if (!hasExpert) return ParticipantsStatus.EXPERT_MISSING;
  if (!hasCustomer) return ParticipantsStatus.CUSTOMER_MISSING;

  return ParticipantsStatus.ALL_JOINED;
};
