import { MODEL_NAMES, type USER_ROLE_NAMES } from "inexone-common/constants";
import type { ThreadMessageAttributes } from "inexone-common/models/ThreadMessage";
import type { MetaNotificationType } from "inexone-common/types/schemas/threadMessage";

import type { Thread, _User as User } from "@/shared/models";
import { cloudCode } from "@/shared/parseHelpers";

import InexUser from "./User";
import ParseObject from "./utils/ParseObject";

export default class ThreadMessage extends ParseObject<ThreadMessageAttributes>(
  MODEL_NAMES.THREAD_MESSAGE,
) {
  getAuthorLabel(): string | undefined {
    const sender = this.getSender();

    if (!sender) {
      return;
    }

    return sender.getFullName();
  }

  getSender(): User {
    return this.get("sender");
  }

  getSenderType(): USER_ROLE_NAMES {
    return this.get("senderType");
  }

  isSentByUser(): boolean {
    return InexUser.current()?.id === this.getSender().id;
  }

  getSentAt(): Date {
    return this.get("sentAt");
  }

  getNotificationType(): MetaNotificationType | undefined {
    const meta = this.get("meta");

    if (meta) {
      return meta.notificationType;
    }
  }

  getNotificationExpertProfileId(): string | undefined {
    const meta = this.get("meta");

    if (meta && "expertProfileId" in meta) {
      return meta.expertProfileId;
    }
  }

  getDeletedStatus(): boolean {
    return this.get("deleted");
  }

  deleteMessage(): Promise<ThreadMessage> {
    return cloudCode("thread_deleteMessage", { threadMessageId: this.id });
  }

  static queryList(thread?: Thread): Parse.Query<ThreadMessage> {
    if (!thread) {
      return ThreadMessage.dummyQuery();
    }

    return ThreadMessage.query()
      .equalTo("thread", thread)
      .include(["sender"])
      .ascending("sentAt")
      .limit(10000);
  }
}
