import { useEffect } from "react";
import Cookies, { type CookieSetOptions } from "universal-cookie";

import { COOKIES } from "inexone-common/constants";

import { isLocalhost } from "@/helpers/utils";
import customizations from "@/shared/customizations";

const secondsInDay = 86400;

const getDomain = (): string | undefined => {
  if (isLocalhost) {
    return window.location.hostname;
  }

  if (
    import.meta.env.VITE_CURRENT_BRANCH === "master" &&
    !customizations.isDemo
  ) {
    return ".inex.one";
  }

  return undefined;
};

const useReferralCookie = (): void => {
  useEffect(() => {
    const search = new URLSearchParams(window?.location?.search);
    const referralId = search.get(COOKIES.REFERRAL_ID);
    const cookieDomain = getDomain();

    if (!(referralId && cookieDomain)) {
      return;
    }

    const cookies = new Cookies();
    const existingReferralId = cookies.get(COOKIES.REFERRAL_ID);

    if (existingReferralId) {
      return;
    }

    const cookieOptions: CookieSetOptions = {
      path: "/",
      domain: cookieDomain,
      maxAge: secondsInDay * 90,
    };

    cookies.set(COOKIES.REFERRAL_ID, referralId, cookieOptions);
    cookies.set(COOKIES.REFERRAL_TIME, new Date().toISOString(), cookieOptions);
  }, []);
};

export default useReferralCookie;
