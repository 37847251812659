import {
  isPlainObject as _isPlainObject,
  isUndefined as _isUndefined,
  omitBy as _omitBy,
} from "lodash-es";
import Parse from "parse";
import type { StaticThis } from "inexone-common/types/utils";

import { IS_STORYBOOK } from "@/shared/constants";

type MaybePointer<U> = U extends Parse.Object ? U | Parse.Pointer : U;

export type ParseConstructorAttrs<T> =
  | {
      [K in keyof T]?: MaybePointer<T[K]>;
    }
  | undefined;

function ParseObjectParent<Attrs extends Parse.Attributes>(className: string) {
  class ParseObject extends Parse.Object<Attrs> {
    constructor(attrs?: ParseConstructorAttrs<Attrs>) {
      const cleanedAttrs = (
        _isPlainObject(attrs) ? _omitBy(attrs, _isUndefined) : attrs
      ) as Attrs;
      super(className, cleanedAttrs);
    }
    static new<T extends Parse.Object>(
      this: StaticThis<T>,
      attrs?: ParseConstructorAttrs<Attrs>,
    ): T {
      return new this(attrs);
    }

    static storybookNew<T extends Parse.Object>(
      this: StaticThis<T>,
      attrs?: ParseConstructorAttrs<Attrs>,
    ): T {
      const object = new this();
      object.id = Math.random().toString(36).substring(2, 11);
      object.isDataAvailable = () => true;

      if (attrs) {
        object.set(attrs);
      }

      return object;
    }

    static dummyQuery<T extends Parse.Object>(
      this: StaticThis<T>,
    ): Parse.Query<T> {
      const overrides = {
        find: () => {
          return Promise.resolve([]);
        },
        get: () => {
          return Promise.resolve(null);
        },
        first: () => {
          return Promise.resolve(null);
        },
      };
      const query = new Parse.Query(this);
      if (IS_STORYBOOK) {
        query.fromLocalDatastore();
      }
      return Object.assign(query, overrides);
    }

    static query<T extends Parse.Object>(this: StaticThis<T>): Parse.Query<T> {
      const query = new Parse.Query(this);

      if (IS_STORYBOOK) {
        query.fromLocalDatastore();
      }

      return query;
    }

    static fromObject<T extends Parse.Object>(
      this: StaticThis<T>,
      json: Partial<Attrs & Parse.JSONBaseAttributes>,
    ): T {
      const object = new this();
      const otherAttributes: Record<string, unknown> = {};
      /**
       * Definition taken from Parse.Object.fromJSON.
       * This allows us to use new this() which makes inheritance also work in
       * the testing environment.
       */
      for (const attr in json) {
        if (attr !== "className" && attr !== "__type") {
          otherAttributes[attr] = json[attr];
        }
      }

      // @ts-expect-error
      object._finishFetch(otherAttributes);
      if (json.objectId) {
        // @ts-expect-error
        object._setExisted(true);
      }
      return object;
    }

    /**
     * When setting values from a form we can run into the issue that Parse will only
     * unset a value if its null. But many fields in the backend are only typed
     * to allow a value or undefined. This fixes that and also handles text inputs which will
     * return "" instead of undefined.
     */
    setFormValues(values: Partial<Attrs>): void {
      this.set(values);

      Object.entries(values).forEach(([key, value]) => {
        if (value === "" || value === undefined) {
          this.unset(key as keyof Attrs);
        }
      });
    }
  }

  return ParseObject;
}

export default ParseObjectParent;
