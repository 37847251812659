import Parse from "parse";

import localStorage from "@/helpers/localStorage";
import WebSocketController from "@/helpers/WebSocketController";
import * as models from "@/shared/models/index";
import { cloudCode } from "@/shared/parseHelpers";

/**
 * This function is run once to initialize Parse, as soon as possible
 * - Parse.initialize
 * - subClass registering (models)
 * - CoreManager's custom controllers
 */
const initParse = (applicationId: string, parseServerUrl: string): void => {
  if (!(applicationId && parseServerUrl)) {
    throw new Error("applicationId and parseServerUrl must be defined");
  }
  Parse.initialize(applicationId);

  Parse.idempotency = true;
  Parse.serverURL = parseServerUrl;

  Object.entries(models).forEach(([modelName, model]) => {
    Parse.Object.registerSubclass(modelName, model);
    if (import.meta.env.DEV) {
      // @ts-expect-error
      window[`_${modelName}`] = model;
    }
  });

  /**
   * Setting custom controllers in CoreManager
   */

  // @ts-expect-error
  Parse.CoreManager.setWebSocketController(WebSocketController);
  // @ts-expect-error
  Parse.CoreManager.setStorageController(localStorage);
  // TODO: parse-server fails to interpret some complex queries embedded in a GET, custom RESTController disabled for now
  // Parse.CoreManager.setRESTController(RESTController);

  if (import.meta.env.DEV) {
    window._Parse = Parse;
    window._ParseCloud = cloudCode;
  }
};

export default initParse;
