import customizations from "@/shared/customizations";

export const setFavicon = () => {
  const getFaviconElement = () =>
    document.querySelector<HTMLLinkElement>('link[rel="icon"]');

  let faviconElement = getFaviconElement();

  if (!faviconElement) {
    faviconElement = document.createElement("link");
    faviconElement.rel = "icon";
    document.head.appendChild(faviconElement);
  }

  const faviconUrl = new URL(
    customizations.favicon ?? "/favicon.ico",
    window.location.origin,
  ).href;

  faviconElement.href = faviconUrl;
};
setFavicon();
