export const PARSE_OBJECT_ID_LENGTH = 10;
export const TEXT_FIELD_MAX_LENGTH = 1000;
export const TEXT_AREA_MAX_LENGTH = 100000;

/** The top 5 most popular non-work email domains */
export const PERSONAL_EMAIL_DOMAINS = [
  "gmail.com", // Google
  "yahoo.com", // Yahoo
  "hotmail.com", // Microsoft
  "outlook.com", // Microsoft
  "icloud.com", // Apple
];
