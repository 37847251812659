import type { ValuesOf } from "../types/utils";

export const ClientRoles = {
  /** This role name is used to represent a regular user. */
  USER: "user",
  COMPLIANCE: "compliance",
  /** This role name is used to represent an admin user. */
  ADMIN: "admin",
} as const;
export type ClientRoles = ValuesOf<typeof ClientRoles>;

export const ProviderOrgRoles = {
  /** The base role for a provider organization */
  USER: "user",
  /** basically the same as admins but cant see certain economic data */
  TEAM_LEAD: "teamLead",
  /** The admin role for a provider organization */
  ADMIN: "admin",
} as const;
export type ProviderOrgRoles = ValuesOf<typeof ProviderOrgRoles>;

export const AllRoles = {
  ...ClientRoles,
  ...ProviderOrgRoles,
} as const;

export type AllRoles = ValuesOf<typeof AllRoles>;

export const roleLabels: Record<AllRoles, string> = {
  [AllRoles.USER]: "User",
  [AllRoles.COMPLIANCE]: "Compliance",
  [AllRoles.ADMIN]: "Admin",
  [AllRoles.TEAM_LEAD]: "Team lead",
};

export default AllRoles;
