import CustomError from "es6-error";
import Parse from "parse";

import type * as ApiFunctionsTypes from "inexone-common/types/apiFunctions/utils";

const CONNECTION_FAILED_CODE = 100;
const CLIENT_OUTDATED_CODE = 999;

/*
 * Todo: we might want to do something about this. Its rather unfortunate that
 * parse mixes error codes for quite distinct situations.
 */
const getLoginErrorString = (message: string): string => {
  if (message.indexOf("Your password has expired") !== -1) {
    return 'Your password has expired. Please reset it by clicking on the "Forgot your password?" link above the Log In button.';
  }
  if (message.indexOf("Invalid username/password.") !== -1) {
    return "Invalid username/password.\nFirst time on the platform? Register below";
  }

  return message;
};

// ERROR MANAGEMENT
export class ParseError extends CustomError {
  constructor(
    message: string,
    public code?: number,
  ) {
    super(message);
    // We're always proxying requests, so when the service is unavailable, it becomes invalid JSON (html, plain text, etc.)
    switch (code) {
      case 107:
        this.message = "Service unavailable, please try again in a few minutes";
        break;
      case 100:
        this.message = "Please check your internet connection";
        break;
      case 101:
        this.message = getLoginErrorString(message);
        break;
      case 202:
        this.message = "An account already exists for this username.";
        break;
      default:
    }
  }
}

export const convertError = (err: Error): ParseError => {
  let error: { message: string; code?: number };
  try {
    error = JSON.parse(err.message);
  } catch (_e) {
    error = err;
    // sometimes it seems the object is already parsed
    if (error?.message && typeof error.message === "object") {
      error = error.message;
    }
  }
  return new ParseError(error.message, error.code);
};

export const getCloudCode = ({
  clientVersion,
  apiVersion,
  isStorybook,
  userAgent,
  getMembershipId,
}: {
  clientVersion: string;
  apiVersion: string;
  userAgent: string;
  getMembershipId?: () => string;
  isStorybook?: boolean;
}) => {
  const cloudCode = <FN extends ApiFunctionsTypes.AvailableFunctionNames>(
    functionName: FN,
    params?: ApiFunctionsTypes.FunctionParams<FN>,
  ): Promise<ApiFunctionsTypes.FunctionReturn<FN>> => {
    if (isStorybook) {
      console.warn(`Tried to run ${functionName} in Storybook`);
      // @ts-expect-error
      return Promise.resolve({});
    }

    const paramsToSend = Object.assign(
      {
        clientVersion,
        userAgent,
        membershipId: getMembershipId?.(),
      },
      params,
    );

    return Parse.Cloud.run(
      `${functionName}_v${apiVersion}`,
      paramsToSend,
    ).catch((err) => {
      const error = convertError(err);
      console.error(error);

      if (error && error.code === CLIENT_OUTDATED_CODE) {
        // biome-ignore lint/suspicious/noConsoleLog: eslint migration
        console.log("Client outdated, please reload the page");
      }

      // show error if we have connection problems
      if (error && error.code === CONNECTION_FAILED_CODE) {
        // biome-ignore lint/suspicious/noConsoleLog: eslint migration
        console.log("Connection failed for ", functionName);
      }

      return Promise.reject(error);
    });
  };
  return cloudCode;
};
