import {
  CONFERENCE_TYPE,
  MODEL_NAMES,
  USER_ROLE_NAMES,
} from "inexone-common/constants";
import * as fVal from "inexone-common/validation/fieldValidation";

export enum EventType {
  MeetingStarted = "meeting-started",
  MeetingEnded = "meeting-ended",
  ParticipantJoined = "participant-joined",
  ParticipantLeft = "participant-left",
  RecordingStarted = "recording-started",
  RecordingCompleted = "recording-completed",
}

export const callLogAttributesShape = {
  meetingType: fVal.enum([CONFERENCE_TYPE.TWILIO, CONFERENCE_TYPE.ZOOM]),
  booking: fVal.parseObjectRef(MODEL_NAMES.BOOKING),

  /**
   * Date at which the event occurred. This is the date that should be
   * taken into account for sorting the call logs, to account for any delay.
   */
  date: fVal.date(),

  /**
   * The room ID:
   * - If meetingType = "zoom", this is the zoom meeting's UUID
   * - If meetingType = "twilio", this is the RoomSid
   *
   * For Twilio, the "reusable" meeting ID (roomName) is the same as the booking ID.
   * For Zoom, the "reusable" meeting ID is stored in the booking.
   *
   * They also both have "unique" IDs for the meeting, which are to be thought of as room IDs.
   * There can be several room IDs for a single booking.
   */
  roomId: fVal.string(),

  /**
   * The event type.
   */
  eventType: fVal.nativeEnum(EventType),

  /**
   * If the event can be tied to a participant, the participant ID,
   * unique to the meeting.
   *
   * - If meetingType = "zoom", this is the zoom participant Sid
   * - If meetingType = "twilio", this is the participant Id
   */
  participantId: fVal.string().optional(),

  participantName: fVal.string().optional().nullable(),
  participantPhoneNumber: fVal.string().optional().nullable(),
  participantRole: fVal.nativeEnum(USER_ROLE_NAMES).optional(),
  participantType: fVal.enum(["web", "phone"]).optional(),
};

const callLogAttributesSchema = fVal.object(callLogAttributesShape);

export type CallLogAttributes = fVal.infer<typeof callLogAttributesSchema>;
