import type {
  AvailableFunctionNames,
  FunctionParams,
  FunctionReturn,
} from "inexone-common/types/apiFunctions/utils";

import {
  APIError,
  callParseAPIFunction,
  type GenericParams,
  type GenericReturn,
} from "./utils";

type FullReturnType<Data> =
  | {
      data: Data;
      error: undefined;
    }
  | {
      data: undefined;
      error: APIError;
    };

/** A generic queryFn */
export const fetchFn = async (
  functionName: string,
  params: GenericParams,
): Promise<FullReturnType<GenericReturn>> => {
  try {
    const data = await callParseAPIFunction(functionName, params);
    return {
      data,
      error: undefined,
    };
  } catch (error) {
    if (error instanceof APIError) {
      return {
        data: undefined,
        error,
      };
    }
    /** Only could happen if decoding fails */
    return {
      data: undefined,
      error: new APIError("Unknown error", 0),
    };
  }
};

export type FetchProxyParams<Params = GenericParams> = [input: Params];

/** Our proxy function type, with the function name as type parameter */
export type FetchProxyFn<FN extends AvailableFunctionNames> = (
  ...args: FetchProxyParams<FunctionParams<FN>>
) => Promise<FullReturnType<FunctionReturn<FN>>>;
