import moment from "moment-timezone";

export function getTimezoneDate(
  timezone: string,
  date: moment.MomentInput = new Date(),
): moment.Moment {
  const momentInTimezone = moment(date).tz(timezone);
  if (!(momentInTimezone?.tz() && momentInTimezone.isValid())) {
    throw new Error("timezone should be a valid tz string");
  }

  return momentInTimezone;
}

export function guessComputerTimezone(): string {
  const timezone = moment.tz.guess();

  return timezone;
}

export function getTimezoneOffsetString(
  timezoneOffset?: number,
  leadingZero?: boolean,
): string {
  if (timezoneOffset === undefined) return "";

  const quotient = timezoneOffset / 60;
  const remainder = timezoneOffset % 60;
  const prefix = timezoneOffset < 0 ? "-" : "+";
  const hours = Math.abs(Math.trunc(quotient));

  /**
   * 40 -> ("040").slice(-2) -> "40",
   * 5 -> "05",
   * 0 -> "00"
   */
  const pad = (number: number) => `0${number}`.slice(-2);

  const hourString = leadingZero ? pad(hours) : hours;

  if (remainder !== 0) {
    const minutes = Math.abs(remainder);
    return `${prefix}${hourString}:${pad(minutes)}`;
  }

  return `${prefix}${hourString}`;
}

type TZDescription = {
  momentInTimezone: moment.Moment;
  // GMT UTC CET GMT+3 (GMT+/- something can happen if the zone has no name)
  abbr: string;
  // Stockholm, CEST
  placeAndAbbr: string;
  // America/New York, EDT
  fullPlaceAndAbbr: string;
  // GMT+3
  offset: string;
  // New York, GMT+3
  placeAndOffset: string;
  // America/New York, GMT+3
  fullPlaceAndOffset: string;
};

export function stripTimezone(date: moment.Moment): moment.Moment {
  return moment(date).utc().add(date.utcOffset(), "m");
}

export function getTZDescription(
  timezone: string,
  date: moment.MomentInput = new Date(),
): TZDescription {
  const momentInTimezone = getTimezoneDate(timezone, date);
  let abbr = momentInTimezone.zoneAbbr();
  const offset = `GMT${getTimezoneOffsetString(
    momentInTimezone.utcOffset(),
    false,
  )}`;

  if (abbr === "UTC") {
    abbr = "GMT";
  }

  let place: string | undefined;
  let fullPlace: string | undefined = timezone.replace(/_/g, " ");

  if (timezone.includes("/")) {
    [, place] = fullPlace.split("/");
  } else {
    place = fullPlace;
  }

  if (place === abbr || place === "UTC") {
    place = undefined;
    fullPlace = undefined;
  }

  if (abbr.startsWith("-") || abbr.startsWith("+")) {
    abbr = `GMT${abbr}`;
  }

  const placeAndAbbr = place ? `${place}, ${abbr}` : abbr;
  const fullPlaceAndAbbr = fullPlace ? `${fullPlace}, ${abbr}` : abbr;

  const placeAndOffset = place ? `${place}, ${offset}` : offset;
  const fullPlaceAndOffset = fullPlace ? `${fullPlace}, ${offset}` : offset;

  return {
    momentInTimezone,
    abbr,
    placeAndAbbr,
    fullPlaceAndAbbr,
    offset,
    placeAndOffset,
    fullPlaceAndOffset,
  };
}
