import { createRoot } from "react-dom/client";

import domains from "@/shared/domains.json";
import windowLocation from "@/shared/windowLocation";

import App from "./pages/root-pages/App";
import initSentry from "./pages/root-pages/helpers/sentry";
import { initPosthog } from "./shared/posthog";

initSentry();
initPosthog();

const redirect = domains.redirects.find(
  ({ host }) => host === windowLocation.hostname,
);
if (redirect) {
  windowLocation.href = `https://${redirect.destination}`;
}

const container = document.getElementById("inexapp");

if (container === null) {
  throw Error("App container not found!");
}

const root = createRoot(container);
root.render(<App />);
