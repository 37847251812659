import {
  MODEL_NAMES,
  NOTIFICATION_COUNTERS_CATEGORIES,
} from "inexone-common/constants";
import type {
  NotificationCounters,
  UserRequestAttributes,
} from "inexone-common/models/UserRequest";

import type { Thread } from "@/shared/models";

import ParseObject from "./utils/ParseObject";

export default class UserRequest extends ParseObject<UserRequestAttributes>(
  MODEL_NAMES.USER_REQUEST,
) {
  private getTotalNotificationCountByCategory(
    category: NOTIFICATION_COUNTERS_CATEGORIES,
  ): number {
    const notificationCounters =
      this.get("notificationCounters")[category] || {};

    return Object.values(notificationCounters).reduce(
      (categoryCount, counter) =>
        !counter.disabled ? categoryCount + counter.count : categoryCount,
      0,
    );
  }

  getTotalThreadNotificationCount(): number {
    return this.getTotalNotificationCountByCategory(
      NOTIFICATION_COUNTERS_CATEGORIES.REQUEST_THREAD_UNREAD_MESSAGES,
    );
  }

  getTotalUnseenExpertNotificationCount(): number {
    return this.getTotalNotificationCountByCategory(
      NOTIFICATION_COUNTERS_CATEGORIES.REQUEST_UNSEEN_EXPERTS,
    );
  }

  private getNotificationCountCategory(
    categoryName: NOTIFICATION_COUNTERS_CATEGORIES,
  ) {
    return this.get("notificationCounters")[categoryName];
  }

  getUnseenExpertNotificationCountCategory(): NotificationCounters | undefined {
    return this.getNotificationCountCategory(
      NOTIFICATION_COUNTERS_CATEGORIES.REQUEST_UNSEEN_EXPERTS,
    );
  }

  private getTargetNotificationCount(
    categoryName: NOTIFICATION_COUNTERS_CATEGORIES,
    targetId: string,
  ): number {
    const category = this.get("notificationCounters")[categoryName];
    return category ? category[targetId]?.count || 0 : 0;
  }

  getThreadNotificationCount(thread: Thread): number {
    return this.getTargetNotificationCount(
      NOTIFICATION_COUNTERS_CATEGORIES.REQUEST_THREAD_UNREAD_MESSAGES,
      thread.id,
    );
  }

  getUnseenExpertNotificationCount(expertProfileId: string): number {
    return this.getTargetNotificationCount(
      NOTIFICATION_COUNTERS_CATEGORIES.REQUEST_UNSEEN_EXPERTS,
      expertProfileId,
    );
  }
}
