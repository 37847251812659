import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { posthog } from "@/shared/posthog";

export default function usePageTrack(): void {
  const { pathname, search } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migration
  useEffect(() => {
    posthog?.capture("$pageview");
  }, [pathname, search]);
}
