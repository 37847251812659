import ExtendableError from "es6-error";

import { decode, encode } from "inexone-common/utils/parseData";

import { useAuth } from "@/helpers/AuthStore";
import User from "@/shared/models/User";
import { applicationId, parseServerUrl } from "@/shared/parseConfig";

export class APIError extends ExtendableError {
  code: number;

  /**
   * @param message The error message
   * @param code `code` is strictly for codes that come from the Parse Server for now.
   * If no code was passed by the Parse Server, use 0.
   * See https://docs.parseplatform.org/rest/guide/#other-issues
   */
  constructor(message: string, code: number) {
    super(message);
    this.code = code;
  }
}

/** Generic types about our API. Parameters and Return types are always object-shaped */
export type GenericReturn = Record<string, unknown>;
export type GenericParams = Record<string, unknown>;

const apiVersion = import.meta.env.VITE_API_VERSION;
const clientVersion = import.meta.env.VITE_CLIENT_VERSION;
const userAgent = window.navigator.userAgent;

const staticParams = {
  clientVersion,
  userAgent,
} as const;

type ParseJSONResponse =
  /** See https://github.com/parse-community/parse-server/blob/b9051372781fccd4fa2c59faf50d08a9170b53c2/src/Routers/FunctionsRouter.js#L104 */
  | {
      result: unknown;
    }
  /** See https://github.com/parse-community/parse-server/blob/65e5879e42cefb4b462c9383af3705d027131242/src/middlewares.js#L429 */
  | {
      error: string;
      code: number;
    };

/**
 * This function calls our Parse functions directly, without the SDK.
 * It doesn't instantiate models and won't work with functions that do, by design
 */
export const callParseAPIFunction = async (
  functionName: string,
  params: Record<string, unknown> = {},
  signal?: AbortSignal,
): Promise<Record<string, unknown>> => {
  const data = {
    ...encode(params),
    ...staticParams,
    membershipId: useAuth.getState().currentWorkspace?.membership?.id,
    _ApplicationId: applicationId,
    _SessionToken: User.current()?.getSessionToken(),
  };

  const jsonResponse: ParseJSONResponse = await fetch(
    `${parseServerUrl}/functions/${functionName}_v${apiVersion}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal,
    },
  )
    .then((response) => response.json())
    .catch((_err) => {
      /**
       * fetch only throws if there is a network failure and we get no response
       */
      throw new APIError("Please check your internet connection", 0);
    });

  if ("error" in jsonResponse) {
    /** `error` is always a string with this endpoint */
    throw new APIError(jsonResponse.error, jsonResponse.code);
  }

  return decode(jsonResponse.result);
};
