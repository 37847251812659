import { MODEL_NAMES } from "inexone-common/constants";
import type { ReferralDataAttributes } from "inexone-common/models/Referral";

import type User from "./User";
import ParseObject from "./utils/ParseObject";

type ReferralAttributes = ReferralDataAttributes & { user?: User };

export default class Referral extends ParseObject<ReferralAttributes>(
  MODEL_NAMES.REFERRAL,
) {}
