import type {
  ORGANIZATION_ROLE_NAMES,
  USER_ROLE_NAMES,
} from "inexone-common/constants";

export type PolicyValue = "privacyPolicy" | "tncPolicy";

export type FeatureValue =
  | "expert_projects"
  | "survey_projects"
  | "expert_compliance";

export type AuthorityValue =
  | `${USER_ROLE_NAMES}` // convert enum to string union
  | ORGANIZATION_ROLE_NAMES
  | "guest"
  | "profileCompleted"
  | "emailVerified"
  | "impersonator"
  | "survey"
  | "expertCall";

export const defaultAuthorityValues: AuthorityValue[] = [];

type AuthorityString = AuthorityValue | `!${AuthorityValue}`;

export type AuthorityInputValue = AuthorityString[][];

export type AuthorityMatchesFunc = (
  ...authType: AuthorityInputValue
) => boolean;

export type FeatureInputValue = FeatureValue;

export type HasFeatureFunc = (feature?: FeatureValue) => boolean;

export type HasPolicyAcceptedFunc = (policy?: PolicyValue) => boolean;

const matchesAuthType = (
  authorityList: Set<AuthorityValue>,
  authorityString: AuthorityString,
): boolean => {
  const isNot = authorityString[0] === "!";

  const authTypeSafe = (
    isNot ? authorityString.slice(1) : authorityString
  ) as AuthorityValue;

  const hasAuth = authorityList.has(authTypeSafe);

  return isNot ? !hasAuth : hasAuth;
};

export const createAuthorityMatches = (
  authorityList: AuthorityValue[],
): AuthorityMatchesFunc => {
  const authorityListSet = new Set(authorityList);

  const authorityMatches: AuthorityMatchesFunc = (...authorityInputValue) => {
    if (authorityInputValue.length === 0) {
      return true;
    }

    return authorityInputValue.some((authorityStrings) =>
      authorityStrings.every((value) =>
        matchesAuthType(authorityListSet, value),
      ),
    );
  };

  return authorityMatches;
};

export const createHasFeature = (
  featureList: FeatureValue[],
): HasFeatureFunc => {
  const hasFeature: HasFeatureFunc = (feature) => {
    if (!feature) {
      return true;
    }

    return featureList.includes(feature);
  };

  return hasFeature;
};

export const createHasPolicyAccepted = (
  policiesList: PolicyValue[],
): HasPolicyAcceptedFunc => {
  const hasPolicyAccepted: HasPolicyAcceptedFunc = (policy) => {
    if (!policy) {
      return true;
    }

    return policiesList.includes(policy);
  };

  return hasPolicyAccepted;
};
