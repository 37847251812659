import { createBrowserHistory, type LocationDescriptor } from "history";

import checkFrontendVersion, {
  getNeedsRefresh,
} from "@/pages/root-pages/helpers/checkFrontendVersion";

const history = createBrowserHistory();
const patchedHistory = {
  ...history,
  push: (
    location: string | LocationDescriptor,
    state?: Record<string, unknown>,
  ): void => {
    if (getNeedsRefresh()) {
      const pathname =
        typeof location === "string"
          ? location
          : (location?.pathname ?? "") + (location?.search ?? "");
      window.location.href = pathname;
      return;
    }
    void checkFrontendVersion();

    history.push(location, {
      ...state,
      formerPathname: history.location.pathname,
    });
  },
};

export default patchedHistory;
