export enum EXPERT_PROFILE_COMPLIANCE_STATES {
  NO_REVIEW_NEEDED = "noReviewNeeded",
  DATA_MISSING = "dataMissing",
  NOT_REVIEWED = "notReviewed",
  DECLINED = "declined",
  APPROVED = "approved",
  REVIEW_REQUESTED = "reviewRequested",
}

export enum EXPERT_PROFILE_STATES {
  DRAFT_DELETED = "draftDeleted",
  /*
   * This state is used to represent an EN firm that has added a new profile to a request
   */
  DRAFT = "draft",
  /*
   * This state is used to represent an EN firm that has published a profile, i.e. made visible to client
   */
  PUBLISHED = "published",
  /*
   * Client has declined the expert
   */
  DECLINED = "declined",
}

export const EXPERT_PROFILE_CONSTANTS = {
  RELEVANCE_RATING_MIN: 0,
  RELEVANCE_RATING_MAX: 3,
  CALL_RATING_MIN: 0,
  CALL_RATING_MAX: 5,
  DURATION_DEFAULT: 60,
  DURATION_MIN: 1,
  DURATION_MAX: 600,
  DURATION_STEP: 15,
  PRIORITY_MIN: 0,
  PRIORITY_MAX: 3,
};

export enum RECRUITMENT_STATUS {
  ADDED = "added",
  CONTACTED = "contacted",
  INTERESTED = "interested",
  CALL_SCHEDULED = "callScheduled",
  DECLINED = "declined",
}

export enum ENRICHMENT_STATUS {
  DISABLED = "disabled",
  IN_PROGRESS = "inProgress",
  FAILED = "failed",
  NOT_FOUND = "notFound",
  SUCCESS = "success",
}
