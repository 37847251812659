import { SurveyStatus } from "./";

export enum SurveyProviderAdminListView {
  INVITES = "invites",
  QUOTING = "quoting",
  IN_PROGRESS = "inProgress",
  CLOSED = "closed",
  DECLINED = "declined",
}

export const surveyStatusToLabel: Partial<Record<SurveyStatus, string>> = {
  [SurveyStatus.CLOSED]: "Closed",
  [SurveyStatus.ON_HOLD]: "On pause",
};
