import { reduce as _reduce } from "lodash";

const patterns = {
  email: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i,
  password: /^.{8,}$/,
  name: /^([ \u00c0-\u01ffa-zA-Z'-])*$/,
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
  organizationNum: /^(\d{10}$)|(^\d{6}-\d{4}$)/,
  fqdn: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.){2,}([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]){2,}$/,
  /**
   * Loose domain name validation
   * See https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
   */
  domainName: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
} as const;

export type MatchFunctions = {
  [keyName in keyof typeof patterns]: (value: unknown) => boolean;
};

/**
 * Matches a string agains one of the patterns defined in patterns
 */
const match = _reduce(
  patterns,
  (sum, pattern, key) => {
    sum[key] = (val: string) => {
      return pattern ? Boolean(pattern.exec(val)) : true;
    };
    return sum;
  },
  {} as Record<string, unknown>,
) as MatchFunctions;

export { patterns, match };
