export enum BOOKING_ATTENDEE_STATUS {
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
  TENTATIVE = "TENTATIVE",
  NEEDS_ACTION = "NEEDS-ACTION",
  DELEGATED = "DELEGATED",
}

export enum BOOKING_STATES {
  /**
   * A booking has been requested. You can see this as a "draft mode" for a booking:
   * There's no date yet
   */
  REQUESTED = "requested",
  /**
   * A booking was scheduled
   */
  SCHEDULED = "scheduled",
  /**
   * Someone requested to reschedule the call
   */
  RESCHEDULE_REQUESTED = "rescheduleRequested",
  /**
   * Call happened. Booking has a completedExpertCall
   */
  COMPLETED = "completed",
  /**
   * Call was canceled
   */
  CANCELED = "canceled",
}

export enum CONFERENCE_TYPE {
  TWILIO = "twilio",
  ZOOM = "zoom",
  MANUAL = "manual",
}

export enum CANCEL_INITIATOR {
  CLIENT = "client",
  EXPERT = "expert",
}
export const CANCEL_REASON_OTHER = "Other";
