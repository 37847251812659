import { Helmet } from "react-helmet";

import customizations from "@/shared/customizations";

const titlePostfix = customizations.pageTitle ?? customizations.name;

const joinText = " | ";

type Props = {
  titles: Array<string | undefined>;
  subTitleCutoff?: number;
  showAppTitle?: boolean;
};

const PageTitle = ({
  titles,
  subTitleCutoff,
  showAppTitle,
}: Props): JSX.Element => {
  const stringTitles = titles.filter(
    (subTitle): subTitle is string => subTitle !== undefined,
  );

  if (showAppTitle) {
    stringTitles.push(titlePostfix);
  }

  const cutoffTitles = stringTitles.map((subTitle, index) => {
    if (
      index !== stringTitles.length - 1 && // don't cut the last item
      subTitleCutoff &&
      subTitle.length > subTitleCutoff + 3
    ) {
      return `${subTitle.substring(0, subTitleCutoff)}...`;
    }

    return subTitle;
  });

  return (
    <Helmet>
      <title>{cutoffTitles.join(joinText)}</title>
    </Helmet>
  );
};

export default PageTitle;
