import { PATHS } from "inexone-common/constants";

function getWait(wait: unknown) {
  return typeof wait === "function" ? wait() : wait;
}

type DeferredType = {
  resolve?: (value: unknown) => void;
  reject?: (reason?: unknown) => void;
  promise?: Promise<unknown>;
} | null;
function defer() {
  const deferred: DeferredType = {};
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
}

/**
 * Debounce function compatible with Promises
 */
export function promiseDebounce<
  // biome-ignore lint/suspicious/noExplicitAny: eslint migration
  Fn extends (...args: any[]) => Promise<unknown>,
>(fn: Fn, wait = 0): Fn {
  let deferred: DeferredType;
  let timer: NodeJS.Timeout;
  type Args = Parameters<Fn>;
  let pendingArgs: Args[] = [];

  type thisType = typeof promiseDebounce;

  function flush(this: thisType) {
    const thisDeferred = deferred;
    clearTimeout(timer);

    void Promise.resolve(
      fn.apply(this, pendingArgs[pendingArgs.length - 1]),
    ).then(thisDeferred?.resolve, thisDeferred?.reject);

    pendingArgs = [];
    deferred = null;
  }

  function debounced(this: thisType, ...args: Args) {
    const currentWait = getWait(wait);
    if (deferred) {
      clearTimeout(timer);
    } else {
      deferred = defer();
    }
    pendingArgs.push(args);
    timer = setTimeout(flush.bind(this), currentWait);

    return deferred.promise;
  }

  return debounced as Fn;
}

export const isPrintPage = (): boolean => {
  const path = window.location.pathname.split("/").slice(1);

  return `/${path[0]}` === PATHS.DEFAULT.PRINT.ROOT;
};

export const dateToSeconds = (date: Date): number =>
  Math.round(date.getTime() / 1000);

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ) ||
    /**
     * When the hostname ends with .ngrok.io,
     * we consider the current URL to be local
     */
    window.location.hostname.match(/\.ngrok\.io$/) ||
    /**
     * *.localhost.inex.app always points to 127.0.0.1
     */
    window.location.hostname.match(/\.localhost\.inex\.app$/),
);

// This works as long as Input precision is 0
export const isValidInteger = (value?: number): boolean =>
  Number.isInteger(value);
