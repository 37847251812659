import { CONFERENCE_TYPE } from "./booking";

type SupportedLanguageInfo = {
  name: string;
  bestModel: string;
  diarization: boolean;
  wordLevelConfidence: boolean;
  twilioCompatibility: boolean;
  zoomCompatibility: boolean;
  enhanced?: boolean;
};

export const supportedLanguages = [
  "zh",
  "zh-TW",
  "cs-CZ",
  "da-DK",
  "en-AU",
  "en-IN",
  "en-SG",
  "en-GB",
  "en-US",
  "fi-FI",
  "fr-FR",
  "de-DE",
  "id-ID",
  "it-IT",
  "ja-JP",
  "ko-KR",
  "pt-BR",
  "es-US",
  "sv-SE",
  "tr-TR",
] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

export const supportedLanguagesDict: Record<
  SupportedLanguage,
  SupportedLanguageInfo
> = {
  zh: {
    name: "Chinese, Mandarin (Simplified, China)",
    bestModel: "default",
    diarization: true,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "zh-TW": {
    name: "Chinese, Mandarin (Traditional, Taiwan)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "cs-CZ": {
    name: "Czech (Czech Republic)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "da-DK": {
    name: "Danish (Denmark)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "en-AU": {
    name: "English (Australia)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "en-IN": {
    name: "English (India)",
    bestModel: "default",
    diarization: true,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "en-SG": {
    name: "English (Singapore)",
    bestModel: "default",
    diarization: true,
    wordLevelConfidence: true,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "en-GB": {
    name: "English (United Kingdom)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: true,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "en-US": {
    name: "English (United States)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: true,
    wordLevelConfidence: true,
    twilioCompatibility: true,
    zoomCompatibility: true,
  },
  "fi-FI": {
    name: "Finnish (Finland)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "fr-FR": {
    name: "French (France)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: true,
    wordLevelConfidence: true,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "de-DE": {
    name: "German (Germany)",
    bestModel: "default",
    diarization: true,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "id-ID": {
    name: "Indonesian (Indonesia)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "it-IT": {
    name: "Italian (Italy)",
    bestModel: "default",
    diarization: true,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "ja-JP": {
    name: "Japanese (Japan)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: true,
    wordLevelConfidence: true,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "ko-KR": {
    name: "Korean (South Korea)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "pt-BR": {
    name: "Portuguese (Brazil)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: true,
    wordLevelConfidence: true,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "es-US": {
    name: "Spanish (United States)",
    bestModel: "phone_call",
    enhanced: true,
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "sv-SE": {
    name: "Swedish (Sweden)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
  "tr-TR": {
    name: "Turkish (Turkey)",
    bestModel: "default",
    diarization: false,
    wordLevelConfidence: false,
    twilioCompatibility: true,
    zoomCompatibility: false,
  },
} as const;

export const defaultLanguage: SupportedLanguage = "en-US";

const twilioLanguages = supportedLanguages.filter(
  (language) => supportedLanguagesDict[language].twilioCompatibility,
);

const zoomLanguages = supportedLanguages.filter(
  (language) => supportedLanguagesDict[language].zoomCompatibility,
);

export const supportedLanguagesByConferenceType: Record<
  CONFERENCE_TYPE,
  SupportedLanguage[]
> = {
  [CONFERENCE_TYPE.ZOOM]: zoomLanguages,
  [CONFERENCE_TYPE.TWILIO]: twilioLanguages,
  [CONFERENCE_TYPE.MANUAL]: [],
};
