import { Spin } from "antd";
import classNames from "classnames";

import styles from "./CenteredSpinner.module.less";

const CenteredSpinner = ({
  className,
  inModal,
  fullHeight,
}: {
  className?: string;
  inModal?: boolean;
  fullHeight?: boolean;
}): JSX.Element => {
  // add a small delay to avoid spinners blinking in an out of existence
  // on fast connections
  return (
    <Spin
      className={classNames(
        styles.spinner,
        {
          [styles.inModal]: inModal,
          [styles.fullHeight]: fullHeight,
        },
        className,
      )}
    />
  );
};

export default CenteredSpinner;
