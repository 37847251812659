import customizations from "@/shared/customizations";

/**
 * @file Holds the shared constants used in the frontend.
 */

/**
 * LocalStorage keys
 */
export const LOCAL_STORAGE_KEYS = {
  REDIRECT_PATH: "redirectPath",
  REDIRECT_SEARCH: "redirectSearch",
  RATE_EXPERT_VIEWED: "rateExpertViewed",
  PROJECT_AFTER_COMPLETE: "projectAfterComplete",
  PROJECT_LIST_SHOW_PRIMARY: "projectListShowPrimary",
  CALL_MONITOR_SHOW_PRIMARY: "callMonitorShowPrimary",
  PROJECT_CLOSE_REMINDER: "projectCloseReminder",
  THREAD_DRAFTS: "threadDrafts",
  SELECTED_MEMBERSHIP_ID: "selectedMembershipId",
} as const;

/**
 * Ids of different modals
 */
export const MODAL_IDS = {
  ATTACHMENT_CREATE: "attachmentCreate",
  BOOKING_CREATE_EDIT: "bookingCreateEdit",
  BOOKING_COMPLETE: "bookingComplete",
  BOOKING_CALL_IN_DETAILS: "bookingCallInDetails",
  BOOKING_CANCEL: "bookingCancel",
  BOOKING_REQUEST: "bookingRequest",
  BOOKING_RESCHEDULE_REQUEST: "bookingRescheduleRequest",
  COMPLETED_CALL_EDIT: "completedCallEdit",
  CALL_TIMELINE_CHAT: "callTimelineChat",
  CALL_RATING: "callRating",
  DECLINE_PROJECT: "declineProject",
  DIAL_IN_EXPERT: "dialInExpert",
  EXPERT_PROFILE_ADD_TO_PROJECT: "expertProfileAddToProject",
  EXPERT_PROFILE_DECLINE: "expertProfileDecline",
  EXPERT_PROFILE_EDIT: "expertProfileEdit",
  EXPERT_PROFILE_PROMPT_AVAILABILITIES: "expertProfilePromptAvailabilities",
  EXPERT_PROFILE_VIEW_AVAILABILITIES: "expertProfileViewAvailabilities",
  EXPERT_PROFILE_EDIT_AVAILABILITIES: "expertProfileEditAvailabilities",
  CHAT_MESSAGE_ALL: "chatMessageAll",
  SURVEY_MESSAGE_ALL: "surveyMessageAll",
  REQUEST_ACCEPT_INVITE: "requestAcceptInvite",
  REQUEST_CLOSE: "requestClose", // This is used hardcoded in request.ts in backend
  REQUEST_EN_FIRM_EDIT: "requestENFirmEdit",
  REQUEST_INVITE_USERS: "requestInviteUsers",
  EN_REQUEST_INVITE_CUSTOMER_USERS: "ENRequestInviteCustomerUsers",
  THREAD_MESSAGE_DELETE: "threadMessageDelete",
  USER_INVITE_COLLEAGUES: "inviteColleagues",
  ADD_ANGLE_EXPECTED_CALLS: "addAngleExpectedCalls",
} as const;

export enum MEDIA_QUERY_OPTIONS {
  MIN_WIDTH_MOBILE = "minWidthMobile",
  MIN_WIDTH_SMALL_TABLET = "minWidthSmallTablet",
  MIN_WIDTH_TABLET = "minWidthTablet",
  MIN_WIDTH_SMALL_DESKTOP = "minWidthSmallDesktop",
  MIN_WIDTH_DESKTOP = "minWidthDesktop",
  MIN_WIDTH_LARGE_DESKTOP = "minWidthLargeDesktop",
  MIN_WIDTH_PANEL_WITH_SIDE_FILTERS = "minWidthPanelWithSideFilters",
}

export const MINWIDTH_MEDIA_SIZES: Record<MEDIA_QUERY_OPTIONS, number> = {
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_MOBILE]: 480,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_SMALL_TABLET]: 768,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_TABLET]: 992,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_SMALL_DESKTOP]: 1200,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_DESKTOP]: 1366,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_LARGE_DESKTOP]: 1600,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_PANEL_WITH_SIDE_FILTERS]: 1744,
};

/**
 * The limit of expert networks that can be selected.
 */
export const MAX_EN_SELECTED = 5;

/**
 * Don't check for a new frontend version more often than this
 * In ms.
 */
export const FRONTEND_CHECK_STALE = 1000 * 60 * 5;

/**
 * The debug flag.
 */
export const __DEBUG__ = import.meta.env.DEV;

/**
 * The default URL.
 */
export const DEFAULT_URL =
  import.meta.env.VITE_DEFAULT_URL ?? "https://app.inex.one";

/**
 * Whether to disable the subdomain mode
 * - When in the subdomain mode, we'll try to resolve the current workspace from the subdomain
 * - When disabled, we'll start with the default workspace and store the selected workspace in the local storage
 */
export const DISABLE_SUBDOMAINS =
  import.meta.env.VITE_DISABLE_SUBDOMAINS === "true" ||
  customizations.disableSubdomains;

/**
 * Intercom app id.
 */
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

const VITE_STORYBOOK_LOCAL_DATA = import.meta.env.VITE_STORYBOOK_LOCAL_DATA;
export const IS_STORYBOOK = VITE_STORYBOOK_LOCAL_DATA === "true";
