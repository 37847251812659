import {
  createAuthorityMatches,
  createHasFeature,
  createHasPolicyAccepted,
  defaultAuthorityValues,
  type AuthorityValue,
  type FeatureValue,
  type PolicyValue,
} from "inexone-common/utils/authAndFeatures";

import type { AuthObject } from "./types";

const createAuthCheckUtils = (
  authorityList: AuthorityValue[] = defaultAuthorityValues,
  featureList: FeatureValue[] = [],
  policiesList: PolicyValue[] = [],
): Pick<
  AuthObject,
  | "authorityMatches"
  | "hasFeature"
  | "hasPolicyAccepted"
  | "isCustomer"
  | "isContractor"
  | "isENAdminOrTL"
  | "isImpersonator"
  | "isGuest"
  | "interfaceType"
> => {
  const authorityMatches = createAuthorityMatches(authorityList);
  const hasFeature = createHasFeature(featureList);
  const hasPolicyAccepted = createHasPolicyAccepted(policiesList);

  const isCustomer = authorityMatches(["customer"]);
  const isContractor = authorityMatches(["contractor"]);
  const isGuest = authorityMatches(["guest"]);
  const isENAdminOrTL =
    !isCustomer && authorityMatches(["teamLead"], ["admin"]);
  const isImpersonator = authorityMatches(["impersonator"]);
  return {
    authorityMatches,
    hasFeature,
    hasPolicyAccepted,
    isGuest,
    isCustomer,
    isENAdminOrTL,
    isImpersonator,
    isContractor,
    interfaceType: isGuest ? "guest" : isCustomer ? "buying" : "selling",
  };
};

export default createAuthCheckUtils;
