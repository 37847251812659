import type {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import type {
  AvailableFunctionNames,
  FunctionParams,
  FunctionReturn,
} from "inexone-common/types/apiFunctions/utils";

import { cloudCode } from "@/shared/parseHelpers";

import {
  callParseAPIFunction,
  type APIError,
  type GenericParams,
  type GenericReturn,
} from "./utils";

/**
 * Our query key is composed of the function name,
 * and a raw object containing parameters
 */
type QueryKey<FN = string, Params = GenericParams> = readonly [
  functionName: FN,
  params: Params,
];

/**
 * Options from react-query that we're exposing
 *
 * We're providing queryFn and the queryKey,
 * and everything else is passed
 */
type QueryOptions<
  FN = string,
  Params = GenericParams,
  Return = GenericReturn,
  Data = GenericReturn,
> = Omit<
  UseQueryOptions<Return, unknown, Data, QueryKey<FN, Params>>,
  "queryKey" | "queryFn"
>;

/** A generic queryFn */
export const queryFn: QueryFunction<GenericReturn, QueryKey> = async ({
  queryKey,
  signal,
}) => {
  const [functionName, params] = queryKey;
  return callParseAPIFunction(functionName, params, signal);
};

/** A generic queryFn */
export const parseQueryFn: QueryFunction<GenericReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [functionName, params] = queryKey;
  return cloudCode(functionName as AvailableFunctionNames, params);
};

/** Parameters for our proxy function */
export type UseQueryProxyParams<
  Params = GenericParams,
  Options = QueryOptions,
> = [input: Params, opts?: Options];

/** Our proxy function type, with the function name as type parameter */
export type UseQueryProxyFn<FN extends AvailableFunctionNames> = <
  Data = FunctionReturn<FN>,
>(
  ...args: UseQueryProxyParams<
    FunctionParams<FN>,
    QueryOptions<FN, FunctionParams<FN>, FunctionReturn<FN>, Data>
  >
) => UseQueryResult<Data, APIError> & { isPendingWithEnabled: boolean };

export type UseGetQueryKeyProxyParams<Params = GenericParams> = [
  input?: Partial<Params>,
];

export type UseGetQueryKeyProxyFn<FN extends AvailableFunctionNames> = (
  ...args: UseGetQueryKeyProxyParams<FunctionParams<FN>>
) => QueryKey<FN, FunctionParams<FN>>;
