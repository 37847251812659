export enum DIGEST_INTERVAL_OPTIONS {
  EVERY_TWO_HOURS = "everyTwoHours",
  THRICE_DAILY = "thriceDaily",
  DAILY = "daily",
}

// TODO: remove this and use REQUEST_NOTIFICATION_SETTINGS_CATEGORIES instead of this one
export enum REQUEST_NOTIFICATION_CATEGORIES {
  EXPERTS = "requestExperts",
  OTHER = "requestOther",
  CALENDAR_INVITES = "requestCalendarInvites",
  MESSAGES = "requestMessages",
  COMPLIANCE = "requestCompliance",
  DIGEST = "requestDigest",
}

export enum NOTIFICATION_COUNTERS_CATEGORIES {
  REQUEST_THREAD_UNREAD_MESSAGES = "requestThreadUnreadMessages",
  REQUEST_UNSEEN_EXPERTS = "requestUnseenExperts",
}

export enum SURVEY_NOTIFICATION_COUNTERS_CATEGORIES {
  THREAD_UNREAD_MESSAGES = "threadUnreadMessages",
  QUOTE_NOTIFICATIONS = "quoteNotifications",
  BILLING_NOTIFICATIONS = "billingNotifications",
}

export enum SURVEY_NOTIFICATION_SETTINGS_CATEGORIES {
  NEW_MESSAGES = "newMessages",
  PROJECT_UPDATES = "projectUpdates",
  QUOTES_AND_BILLING = "quotesAndBilling",
}

export enum REQUEST_NOTIFICATION_SETTINGS_CATEGORIES {
  NEW_MESSAGES = "requestNewMessages",
  EXPERT_UPDATES = "requestExpertUpdates",
  DAILY_DIGEST = "requestDailyDigest",
  PROJECT_UPDATES = "requestProjectUpdates",
}

export enum NOTIFICATION_EVENTS {
  EXPERT_PROFILE_PUBLISHED = "expertProfilePublished",
  EXPERT_PROFILE_AVAILABILITY_PUBLISHED = "expertProfileAvailabilityPublished",
  EXPERT_PROFILE_REQUEST_CREATED = "expertProfileRequestCreated",
  EXPERT_PROFILE_REQUEST_CANCELED = "expertProfileRequestCanceled",
  EXPERT_PROFILE_RESCHEDULE_CREATED = "expertProfileRescheduleCreated",
  EXPERT_PROFILE_RESCHEDULE_CANCELED = "expertProfileRescheduleCanceled",
  EXPERT_PROFILE_DECLINE = "expertProfileDecline",
  EXPERT_PROFILE_UNPUBLISH = "expertProfileUnpublish",
  EXPERT_PROFILE_COMPLIANCE_REQUESTED = "expertProfileComplianceRequested",
  EXPERT_PROFILE_COMPLIANCE_APPROVED = "expertProfileComplianceApproved",
  EXPERT_PROFILE_COMPLIANCE_DECLINED = "expertProfileComplianceDeclined",

  DIGEST_CLIENT = "digestClient",
  DIGEST_CLIENT_AVAILABILITY = "digestClientAvailability",
  DIGEST_COMPLIANCE_REQUEST = "digestComplianceRequest",

  BOOKING_CREATED = "bookingCreated",
  BOOKING_EDITED = "bookingEdited",
  BOOKING_CANCELED = "bookingCanceled",
  BOOKING_COMPLETED = "bookingCompleted",
  BOOKING_TRANSCRIPT_READY = "bookingTranscriptReady",
  BOOKING_REMINDED = "bookingReminded",
  BOOKING_EXPERT_OVERDUE = "bookingExpertOverdue",
  BOOKING_CALENDAR_DECLINED = "bookingCalendarDeclined",

  /**
   * Call Not Joined Warning (Notification)
   *
   * notifications when one or more participants haven't joined
   * an ongoing expert call.
   */
  BOOKING_CALL_NOT_JOINED_WARNING = "bookingCallNotJoinedWarning",

  REQUEST_INVITE_ACCEPTED = "requestInviteAccepted",
  REQUEST_INVITE_EXISTING_USER = "requestInviteExistingUser",
  REQUEST_INVITE_NEW_USER = "requestInviteNewUser",
  REQUEST_COMPLIANCE_INVITED = "requestComplianceInvited",
  REQUEST_UPDATED = "requestUpdated",
  REQUEST_STATUS_IN_PROGRESS = "requestStatusInProgress",
  REQUEST_STATUS_ON_HOLD = "requestStatusOnHold",
  REQUEST_STATUS_CLOSED = "requestStatusClosed",
  REQUEST_CLOSE_OVERDUE = "requestCloseOverdue",
  REQUEST_CLIENT_AVAILABILITY_UPDATED = "clientAvailabilityUpdated",

  /* A client was invited to a request by an EN */
  REQUEST_CLIENT_INVITED = "requestClientInvited",
  /** A client has accepted the invite from an EN */
  REQUEST_CLIENT_INVITE_ACCEPTED = "requestClientInviteAccepted",
  /** A client has declined the invite from an EN */
  REQUEST_CLIENT_INVITE_DECLINED = "requestClientInviteDeclined",

  REQUEST_ENFIRM_WITHOUT_RESPONSES = "requestEnFirmWithoutResponses",
  REQUEST_ENFIRM_WITHOUT_EXPERTS = "requestEnFirmWithoutExperts",

  EXPERT_NOTE_MESSAGE = "expertNoteMessage",
  THREAD_REQUEST_MESSAGE = "threadRequestMessage",
  THREAD_SURVEY_MESSAGE = "threadSurveyMessage",

  ORGANIZATION_USER_INVITED = "organizationUsersInvited",
  USER_CREATED = "userCreated",
  VERIFY_EMAIL = "verifyEmail",
  ADMIN_MAGIC_LINK = "adminMagicLink",
  RESET_PASSWORD = "resetPassword",
  INTERNAL_PROJECT_EMAIL_FORWARD = "internalProjectEmailForward",
  INTERNAL_CALENDAR_REPLY_FAILED = "internalCalendarReplyFailed",

  // QUOTES & BILLING
  SURVEY_QUOTE_CREATED = "surveyQuoteCreated",
  SURVEY_QUOTE_UPDATED = "surveyQuoteUpdated",
  SURVEY_INVOICE_CREATED = "surveyInvoiceCreated",
  SURVEY_INVOICE_UPDATED = "surveyInvoiceUpdated",
  SURVEY_ACCEPT_QUOTE = "surveyAcceptQuote",
  SURVEY_REQUEST_INVOICE = "surveyRequestInvoice",
  SURVEY_ACCEPT_INVOICE = "surveyAcceptInvoice",

  // PROJECT UPDATES
  SURVEY_STATUS_IN_PROGRESS = "surveyStatusInProgress",
  SURVEY_STATUS_ON_HOLD = "surveyStatusOnHold",
  SURVEY_STATUS_CLOSED = "surveyStatusClosed",
  SURVEY_UPDATED = "surveyUpdated",
  SURVEY_PROVIDER_DECLINED = "surveyProviderDeclined",
  SURVEY_PROVIDER_UNDO_DECLINED = "surveyProviderUndoDeclined",
  SURVEY_CLIENT_DECLINED = "surveyClientDeclined",
  SURVEY_CLIENT_UNDO_DECLINED = "surveyClientUndoDeclined",
  SURVEY_PROJECT_ACCEPTED = "surveyProjectAccepted",
  SURVEY_PROVIDER_STATUS_QUOTING = "surveyProviderStatusQuoting",
  SURVEY_PROVIDER_STATUS_QUESTIONNAIRE_DESIGN = "surveyProviderStatusQuestionnaireDesign",
  SURVEY_PROVIDER_STATUS_FIELDING = "surveyProviderStatusFielding",
  SURVEY_PROVIDER_STATUS_PROGRAMMING = "surveyProviderStatusProgramming",
  SURVEY_INVITE_EXISTING_USER = "surveyInviteExistingUser",
  SURVEY_INVITE_NEW_USER = "surveyInviteNewUser",
}
