import * as z from "zod";

/**
 * WARNING: Only use these validators with .parseAsync and .safeParseAsync
 */

export const phone = () =>
  z
    .string({ required_error: "Please add a phone number" })
    .refine(async (value) => {
      const { isValidPhoneNumber } = await import("libphonenumber-js");

      return isValidPhoneNumber(value);
    }, "Invalid phone number")
    .transform(async (text) => {
      const { parsePhoneNumberFromString } = await import("libphonenumber-js");

      return parsePhoneNumberFromString(text)?.number as string;
    });
