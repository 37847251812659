import { message } from "antd";
import moment from "moment";
import { generatePath } from "react-router";
import { create, type StoreApi } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import PATHS, {
  BACK_END_PATHS,
  BECOME_QUERY_PARAMS,
} from "inexone-common/constants/paths";
import type { FunctionParams } from "inexone-common/types/apiFunctions/utils";
import type { Workspace } from "inexone-common/types/schemas/workspaces";
import { appendBecomeQuery } from "inexone-common/utils/path";
import * as fVal from "inexone-common/validation/fieldValidation";

import { guessComputerTimezone } from "@/helpers/time/timezone";
import queryCache from "@/hooks/parse-hooks/utils/queryCache";
// The api code depends on it, so we're avoiding a circular dependency
import { apiProxy as api } from "@/hooks/reactQuery/apiProxy";
import { DISABLE_SUBDOMAINS, LOCAL_STORAGE_KEYS } from "@/shared/constants";
import history from "@/shared/history";
import liveQueryClient from "@/shared/liveQueryClient";
import { _User as User } from "@/shared/models";
import { cloudCode, convertError } from "@/shared/parseHelpers";
import { queryClient } from "@/shared/queryClient";
import windowLocation from "@/shared/windowLocation";

import createAuthCheckUtils from "./createAuthCheckUtils";
import getTimezoneName from "./getTimezoneName";
import redirectOnAuthState, { type UserAndAuth } from "./redirectOnAuthState";
import type { AuthObject } from "./types";

const { USER, SIGNED_IN_USER } = PATHS;

const _handleProjectAfterCompleteSSO = async (
  isUserProfileComplete: boolean,
) => {
  const afterComplete = localStorage.getItem(
    LOCAL_STORAGE_KEYS.PROJECT_AFTER_COMPLETE,
  );
  if (isUserProfileComplete && afterComplete) {
    try {
      const { interviewTimeline, ...projectAttributes } =
        JSON.parse(afterComplete);
      const draftProjectAttributes = {
        interviewTimeline: {
          startDate: interviewTimeline.startDate
            ? new Date(interviewTimeline.startDate)
            : undefined,
          dueDate: interviewTimeline.dueDate
            ? new Date(interviewTimeline.dueDate)
            : undefined,
        },
        ...projectAttributes,
      };

      const response = await api.request_create.fetch({
        timezone: guessComputerTimezone(),
      });

      const requestId = response?.data?.requestId;

      if (requestId) {
        await api.request_edit.fetch({
          requestId,
          formValues: draftProjectAttributes,
        });

        const path = generatePath(PATHS.DEFAULT.REQUESTS.EDIT.BRIEF, {
          requestId: requestId,
        });

        history.push(path);
      }
    } catch (_error) {
      await message.warning("Couldn't create the draft project");
    }
    localStorage.removeItem(LOCAL_STORAGE_KEYS.PROJECT_AFTER_COMPLETE);
  }
};

const currentSlug = fVal.urlWithSlug().safeParse(window.location.href);
const getCurrentWorkspace = <T extends Workspace>(
  workspaces: T[],
): T | undefined => {
  if (DISABLE_SUBDOMAINS) {
    const localStorageWorkspace = workspaces.find(
      (workspace) =>
        workspace.membership.id ===
        localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_MEMBERSHIP_ID),
    );
    if (localStorageWorkspace) {
      return localStorageWorkspace;
    }
  }
  if (currentSlug.success) {
    const slugWorkspace = workspaces.find(
      (workspace) => workspace.organization.slug === currentSlug.data,
    );
    if (slugWorkspace) {
      return slugWorkspace;
    }
  }

  return workspaces.find((workspace) => workspace.isDefault) ?? workspaces[0];
};
// actions
const _fetchUserAndAuth = async (): Promise<UserAndAuth | undefined> => {
  const currentUser = User.current();

  if (currentUser) {
    const { user, workspaces } = await cloudCode("user_fetchWithInfo");

    const currentWorkspace = getCurrentWorkspace(workspaces);
    if (!currentWorkspace) {
      throw new Error("No workspace found");
    }

    return {
      user,
      workspaces,
      currentWorkspace,
      authorityList: currentWorkspace.authorityList,
      featureList: currentWorkspace.featureList,
      policiesList: currentWorkspace.policiesList,
    };
  }

  return undefined;
};

const _updateVisitedAt = async (user: User, isImpersonator: boolean) => {
  if (isImpersonator) {
    return;
  }

  let isChanged = false;

  if (!user.get("timezone")) {
    const timezone = await getTimezoneName();
    user.set("timezone", timezone);
    isChanged = true;
  }

  const oldLastSeenAt = user.get("lastSeenAt");

  if (!oldLastSeenAt || moment().diff(oldLastSeenAt, "minutes") > 5) {
    user.set("lastSeenAt", new Date());
    isChanged = true;
  }

  if (isChanged) {
    await user.save().catch(() => {});
  }
};

const _initLiveQueries = (
  liveQueriesOpen: boolean,
  set: StoreApi<AuthObject>["setState"],
) => {
  if (liveQueriesOpen === true) {
    return;
  }
  liveQueryClient.open();
  liveQueryClient.on("open", () => {
    set({ liveQueriesOpen: true });
  });
  liveQueryClient.on("close", () => {
    set({ liveQueriesOpen: false });
  });
  liveQueryClient.on("error", () => {
    set({ liveQueriesOpen: false });
  });
};

const _setAuthStateAndCheckUtils = (
  set: StoreApi<AuthObject>["setState"],
  userAndAuth?: UserAndAuth,
) => {
  const user = userAndAuth?.user;
  const currentWorkspace = userAndAuth?.currentWorkspace;
  const workspaces = userAndAuth?.workspaces;

  set({
    currentUser: user ?? User.fromObject({}),
    currentWorkspace,
    workspaces,
    ...createAuthCheckUtils(
      userAndAuth?.authorityList,
      userAndAuth?.featureList,
      userAndAuth?.policiesList,
    ),
  });
};

const _logOutUser = async (
  set: StoreApi<AuthObject>["setState"],
  silent?: boolean,
): Promise<void> => {
  await User.logOut();
  if (!silent) {
    void message.info("You have successfully logged out");
  }
  liveQueryClient.close();
  queryCache.clear();

  void queryClient.invalidateQueries(
    /** Invalidate regardless of the parameters */
    {
      queryKey: api.enRelationship_listAvailableENs.getQueryKey(),
      refetchType: "none",
    },
  );

  _setAuthStateAndCheckUtils(set, undefined);
  redirectOnAuthState(undefined, true);
};

const _checkAppURL = async (userAndAuth: UserAndAuth) => {
  if (DISABLE_SUBDOMAINS) {
    return;
  }

  const currentUser = userAndAuth?.user;
  const currentWorkspace = userAndAuth?.currentWorkspace;

  if (!(currentUser && currentWorkspace)) {
    return;
  }

  const appURL = currentWorkspace.organization.url;

  const token = currentUser.getSessionToken();

  const appURLHost = new URL(appURL).host;
  const currentHost = windowLocation.host;

  if (appURLHost !== currentHost && token) {
    const becomePath = generatePath(PATHS.USER.BECOME, {
      token,
    });
    const redirectURL = new URL(becomePath, appURL);

    redirectURL.search = window.location.search;

    const currentPath = windowLocation.pathname;
    const isOnAccountActionPage =
      currentPath.startsWith(USER.ROOT) ||
      currentPath.startsWith(SIGNED_IN_USER.ROOT);

    /**
     * If we're not on an account action page, retain the path for a smooth experience
     */
    if (!isOnAccountActionPage) {
      appendBecomeQuery(
        redirectURL.searchParams,
        BECOME_QUERY_PARAMS.REDIRECT,
        windowLocation.pathname,
      );
    }

    /** Retain the search parameters */
    windowLocation.href = redirectURL.href;
  }
};

const _initUser = async (
  set: StoreApi<AuthObject>["setState"],
  get: StoreApi<AuthObject>["getState"],
) => {
  const userAndAuth = await _fetchUserAndAuth().catch((error) => {
    if (error.code === 209) {
      /**
       * Log the user out if the session token is wrong. That fixes:
       * - "Invalid session token" errors in /user/* pages
       * - Data leak if we remotely remove a compromised session token
       */
      void message.warn("Your session expired, please log in again");
      void _logOutUser(set);
    } else if (error.code === 101) {
      /** Permission denied */
      void message.warn("Access denied, please log in again");
      void _logOutUser(set);
    } else {
      console.error(error);
      /**
       * In this case we know very little about it, likely the server
       * is down or the user doesn't exist anymore
       */
      void message.error("Authentication failed");
    }

    return undefined;
  });

  const user = userAndAuth?.user;

  if (user) {
    await _checkAppURL(userAndAuth);
  }

  _setAuthStateAndCheckUtils(set, userAndAuth);
  redirectOnAuthState(userAndAuth, get().initialized);

  if (!user) {
    return;
  }

  await _handleProjectAfterCompleteSSO(
    get().authorityMatches(["profileCompleted"]),
  );
  await _updateVisitedAt(user, get().authorityMatches(["impersonator"]));
  _initLiveQueries(get().liveQueriesOpen, set);
};

const _updateUser = (attributes: FunctionParams<"user_editProfile">) => {
  return cloudCode("user_editProfile", attributes);
};

const _acceptUserPolicies = async (policyIds: string[]): Promise<void> => {
  await cloudCode("user_acceptPolicies", { policyIds });
};

const _checkAccountExistence = (
  email: string,
): Promise<{
  exists: boolean;
  isAllowed: boolean;
}> => {
  return cloudCode("user_checkAccountExistence", { email });
};

export const _goToWorkspace = async (
  get: StoreApi<AuthObject>["getState"],
  targetWorkspaceId: string,
): Promise<void> => {
  if (get().currentWorkspace?.membership.id === targetWorkspaceId) {
    return;
  }
  const targetWorkspace = get().workspaces.find(
    (workspace) => workspace.membership.id === targetWorkspaceId,
  );

  if (!targetWorkspace) {
    return;
  }

  if (DISABLE_SUBDOMAINS) {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.SELECTED_MEMBERSHIP_ID,
      targetWorkspace.membership.id,
    );
    await get().initAuthState();
    await queryClient.resetQueries();
    return;
  }
  const sessionToken = get().currentUser?.getSessionToken();
  const appURL = targetWorkspace.organization.url;

  const becomePath = generatePath(PATHS.USER.BECOME, {
    token: sessionToken ?? "",
  });

  // https://app.inex.one/user/become/token
  const becomeURL = new URL(becomePath, appURL);

  appendBecomeQuery(
    becomeURL.searchParams,
    BECOME_QUERY_PARAMS.REDIRECT,
    undefined,
  );

  // Open the workspace in a new tab:
  window.open(becomeURL.href, targetWorkspace.membership.id);
};

export const useAuth = create<AuthObject>()(
  subscribeWithSelector((set, get) => ({
    initialized: false,
    // LiveQueryState
    liveQueriesOpen: false,
    // AuthState
    currentUser: User.fromObject({}),
    currentWorkspace: {} as Workspace,
    workspaces: [],
    // AuthCheckUtils
    ...createAuthCheckUtils(undefined),
    // AuthFunctions
    initAuthState: async () => {
      await _initUser(set, get);
      set({ initialized: true });
    },
    goToWorkspace: (targetWorkspaceId: string) => {
      return _goToWorkspace(get, targetWorkspaceId);
    },
    updateUserProfile: async (
      attributes: FunctionParams<"user_editProfile">,
    ) => {
      await _updateUser(attributes);
      await _initUser(set, get);
    },
    completeProfile: async (
      attributes: FunctionParams<"user_editProfile"> & { policies?: string[] },
    ) => {
      const userAttributes = { ...attributes };
      userAttributes.policies = undefined;

      await _updateUser(userAttributes);
      await _acceptUserPolicies(attributes.policies ?? []);
      await cloudCode("user_completeProfile");
      await _initUser(set, get);
    },
    acceptPolicies: async (policyIds: string[]) => {
      await _acceptUserPolicies(policyIds);
      await _initUser(set, get);
    },
    requestPasswordReset: async (email: string) => {
      try {
        await User.requestPasswordReset(email.toLowerCase());
        return _logOutUser(set, true);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },
    sendVerificationEmail: async (email: string) => {
      try {
        await User.requestEmailVerification(email);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },
    logInUser: async (email: string, password: string) => {
      try {
        await User.logIn(email.toLowerCase(), password);
        await _initUser(set, get);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },
    logInWithSSO: (email: string) => {
      const ssoURL = new URL(BACK_END_PATHS.SSO_AUTH, window.location.origin);
      ssoURL.searchParams.append("email", email);
      location.href = ssoURL.href;
    },
    logOutUser: async (silent?: boolean) => {
      try {
        await _logOutUser(set, silent);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },

    becomeUser: async (sessionToken: string) => {
      const currentUser = User.current();

      if (currentUser) {
        /**
         * If a user is already logged in, we check whether the
         * session token is still the same.
         * If it is, return redirectOnAuthState on the redirectPath.
         * Otherwise, log out first before becoming user.
         */
        if (currentUser.getSessionToken() === sessionToken) {
          const userAndAuth = await _fetchUserAndAuth();

          return redirectOnAuthState(userAndAuth, true);
        }

        await _logOutUser(set, true);
      }

      try {
        await User.become(sessionToken);
        return _initUser(set, get);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },
    signUpUser: async ({
      email,
      password,
      policies,
    }: {
      email: string;
      password: string;
      policies: string[];
    }) => {
      const newUser = new User({
        email: email.toLowerCase(),
        username: email.toLowerCase(),
        password,
      });

      try {
        await newUser.signUp();
        await _acceptUserPolicies(policies);
        return _initUser(set, get);
      } catch (error) {
        if (error instanceof Error) {
          await Promise.reject(convertError(error));
        }
      }
    },
    checkAccountExistence: async (email: string) => {
      return await _checkAccountExistence(email);
    },
  })),
);
