enum ssoMethod {
  /**
   * OAuth with Google. Suitable for companies using Google Workspace
   * to identify their users
   */
  GOOGLE = "GoogleOAuth",
  /**
   * OAuth with Microsoft (Azure). Suitable for companies using Microsoft
   * to identify their users
   */
  MICROSOFT = "MicrosoftOAuth",
  /**
   * Specific enterprise connection set up on WorkOS
   * E.g. Azure SAML
   */
  CUSTOM = "custom",
  /**
   * Email / Password login
   */
  NONE = "none",
}

export default ssoMethod;
