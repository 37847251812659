import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

import type {
  AvailableFunctionNames,
  FunctionParams,
  FunctionReturn,
} from "inexone-common/types/apiFunctions/utils";

import { cloudCode } from "@/shared/parseHelpers";

import {
  callParseAPIFunction,
  type APIError,
  type GenericParams,
  type GenericReturn,
} from "./utils";

/**
 * Options from react-query that we're exposing
 *
 * We're providing mutationKey and mutationFn,
 * and everything else is passed
 */
type MutationOptions<Params = GenericParams, Return = GenericReturn> = Omit<
  UseMutationOptions<Return, APIError, Params>,
  "mutationKey" | "mutationFn"
>;

/** A generic way to get a mutationFn */
export const getMutationFn =
  (functionName: string): MutationFunction<GenericReturn, GenericParams> =>
  (variables) =>
    callParseAPIFunction(functionName, variables);

/** A generic way to get a mutationFn */
export const getParseMutationFn =
  (functionName: string): MutationFunction<GenericReturn, GenericParams> =>
  (variables) =>
    cloudCode(functionName as AvailableFunctionNames, variables);

/** Parameters for our proxy function */
export type UseMutationProxyParams<Options = MutationOptions> = [
  opts?: Options,
];

/** Our proxy function type, with the function name as type parameter */
export type UseMutationProxyFn<FN extends AvailableFunctionNames> = (
  ...args: UseMutationProxyParams<
    MutationOptions<FunctionParams<FN>, FunctionReturn<FN>>
  >
) => UseMutationResult<FunctionReturn<FN>, APIError, FunctionParams<FN>>;
