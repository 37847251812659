import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";

import windowLocation from "@/shared/windowLocation";

import Exception from "./Exception/Exception";

const ErrorBoundary = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const { showNewMessage } = useIntercom();
  return (
    <Sentry.ErrorBoundary
      onError={() => {
        if (import.meta.env.PROD) {
          showNewMessage();
        }
      }}
      fallback={({ error }) => {
        if (import.meta.env.DEV) {
          console.error(error);
        }

        // @ts-expect-error (filename property does not exist on errors, this is a hack in asyncLoad.tsx)
        const fileName = error.fileName;

        if (fileName) {
          return (
            <Exception
              type="500"
              header="A component failed to load"
              description="This could be due to an outdated version or internet connectivity issues."
              errorText={error.message}
              action={{
                label: "Reload page",
                onClick: () => windowLocation.reload(),
              }}
            />
          );
        }

        return (
          <Exception
            type="500"
            header="Something went wrong"
            errorText={error.message}
          />
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
