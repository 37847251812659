import Parse from "parse";

import {
  convertError as convertErrorCommon,
  getCloudCode,
  ParseError,
} from "inexone-common/utils/parseHelpers";

import { useAuth } from "@/helpers/AuthStore";

import { IS_STORYBOOK } from "./constants";

const clientVersion = import.meta.env.VITE_CLIENT_VERSION;
const apiVersion = import.meta.env.VITE_API_VERSION;

const isDev = import.meta.env.DEV;

const userAgent = window?.navigator.userAgent;

export const convertError = (err: Error): ParseError => {
  const parseError = convertErrorCommon(err);
  if (isDev) {
    console.warn(parseError.message);
  }
  return parseError;
};

export { ParseError };

export const forgetAboutObject = (object: Parse.Object): void => {
  // @ts-expect-error
  const stateController = Parse.CoreManager.getObjectStateController();
  // @ts-expect-error
  const stateIdentifier = object._getStateIdentifier();
  stateController.removeState(stateIdentifier);
};

export const cloudCode = getCloudCode({
  clientVersion,
  apiVersion,
  isStorybook: IS_STORYBOOK,
  userAgent,
  getMembershipId: () => useAuth.getState().currentWorkspace?.membership?.id,
});
