import { useMutation, useQuery } from "@tanstack/react-query";

import type {
  AnyApiFunction,
  AnyMutation,
  AnyQuery,
  AvailableFunctions,
} from "inexone-common/types/apiFunctions/utils";
import { createRecursiveProxy } from "inexone-common/utils/createProxy";

import { fetchFn, type FetchProxyFn, type FetchProxyParams } from "./fetch";
import {
  getMutationFn,
  getParseMutationFn,
  type UseMutationProxyFn,
  type UseMutationProxyParams,
} from "./mutation";
import {
  parseQueryFn,
  queryFn,
  type UseGetQueryKeyProxyFn,
  type UseGetQueryKeyProxyParams,
  type UseQueryProxyFn,
  type UseQueryProxyParams,
} from "./query";

type DecorateFunction<
  ApiFunction extends AnyApiFunction,
  FN extends keyof AvailableFunctions,
> = ApiFunction extends AnyQuery
  ? {
      /**
       * Query hook
       *
       * See https://tanstack.com/query/v5/docs/framework/react/guides/queries
       */
      useQuery: UseQueryProxyFn<FN>;
      /** Calls the API straight away, but still won't instantiate Parse objects */
      fetch: FetchProxyFn<FN>;
      /** Get the query key for a given function call */
      getQueryKey: UseGetQueryKeyProxyFn<FN>;
      /**
       * Instantiates Parse objects from the query result.
       * Use for query endpoints which return Parse objects.
       *
       * Avoid using this if possible, as we're trying to move away from Parse.
       * Queries made with this will have the same issues as a cloudCode with a useAction:
       * - Parse objects are mutable, so the component needs to re-render to
       *   show the new data. This is not a problem in a standard setup, as a
       *   fetch will trigger a re-render.
       * - HTTP calls can't be canceled
       */
      _useQueryWithParse: UseQueryProxyFn<FN>;
    }
  : ApiFunction extends AnyMutation
    ? {
        /**
         * Mutation hook
         *
         * See https://tanstack.com/query/v5/docs/framework/react/guides/mutations
         */
        useMutation: UseMutationProxyFn<FN>;
        /** Calls the API straight away, but still won't instantiate Parse objects */
        fetch: FetchProxyFn<FN>;
        /**
         * Instantiates Parse objects from the mutation result.
         * Use for mutation endpoints which return Parse objects.
         *
         * Avoid using this if possible, as we're trying to move away from Parse.
         * Mutation made with this can't be canceled
         */
        _useMutationWithParse: UseMutationProxyFn<FN>;
      }
    : "Endpoint type needs to be QueryApiFunction or MutationApiFunction to be able to use this";

type APIProxy = {
  [FN in keyof AvailableFunctions]: DecorateFunction<
    AvailableFunctions[FN],
    FN
  >;
};

/**
 *
 * Proxy to generate a `useQuery/useMutation/fetch` which calls our API
 *
 * See https://tanstack.com/query/v5/docs/framework/react/guides/queries
 *
 * @example
 * ```ts
 * const MyView = () => {
 *   const { data, isPending, error } = api.team_myStatistics.useQuery({});
 *
 *   const createBillingDetails = api.orgBillingDetails_create.useMutation();
 *
 *   // onClick={() => createBillingDetails.mutate(myParams)}
 *
 *   // onClick={async () => { const {data, error} = await api.team_myStatistics.fetch(myParams) }}
 * }
 * ```
 */
export const apiProxy = createRecursiveProxy((opts) => {
  // opts.path: The path called in the proxy (proxy.myFunction.useQuery(a, b, c) => ["myFunction", "useQuery"])
  // opts.args: The arguments called on the final proxy (proxy.myFunction.useQuery(a, b) => [a, b])

  const [functionName, method] = opts.path;

  if (method === "useMutation") {
    /** Generic version of the params for {@link UseMutationProxyFn} */
    const [options] = opts.args as UseMutationProxyParams;

    // biome-ignore lint/correctness/useHookAtTopLevel: fine in the proxy
    return useMutation({
      mutationKey: [functionName],
      mutationFn: getMutationFn(functionName),
      ...options,
    });
  }
  if (method === "useQuery") {
    /** Generic version of the params for {@link UseQueryProxyFn} */
    const [functionInput, options] = opts.args as UseQueryProxyParams;

    // biome-ignore lint/correctness/useHookAtTopLevel: fine in the proxy
    const useQueryResult = useQuery({
      queryKey: [functionName, functionInput],
      queryFn,
      ...options,
    });

    return {
      ...useQueryResult,
      isPendingWithEnabled:
        options?.enabled === false ? false : useQueryResult.isPending,
    };
  }
  if (method === "getQueryKey") {
    /** If no parameter is supplied, this will invalidate all given the function name */
    const [functionInput] = opts.args as UseGetQueryKeyProxyParams;
    if (functionInput) {
      return [functionName, functionInput];
    }
    return [functionName];
  }
  if (method === "fetch") {
    /** Generic version of the params for {@link FetchProxyFn} */
    const [functionInput] = opts.args as FetchProxyParams;
    return fetchFn(functionName, functionInput);
  }
  if (method === "_useQueryWithParse") {
    /** Generic version of the params for {@link UseQueryProxyFn} */
    const [functionInput, options] = opts.args as UseQueryProxyParams;

    // biome-ignore lint/correctness/useHookAtTopLevel: fine in the proxy
    return useQuery({
      queryKey: [functionName, functionInput],
      queryFn: parseQueryFn,
      ...options,
    });
  }
  if (method === "_useMutationWithParse") {
    /** Generic version of the params for {@link UseMutationProxyFn} */
    const [options] = opts.args as UseMutationProxyParams;

    // biome-ignore lint/correctness/useHookAtTopLevel: fine in the proxy
    return useMutation({
      mutationKey: [functionName],
      mutationFn: getParseMutationFn(functionName),
      ...options,
    });
  }
}) as APIProxy;
