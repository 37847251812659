import type { IOptions } from "sanitize-html";

/**
 * Quill supported formats mapped to HTML equivalent tags
 *
 * default is not a supported quill format,
 * only used to include the most basic tags for sanitize-html
 */

const FORMATS: Record<string, Array<string>> = {
  header: ["h1", "h2", "h3"],
  bold: ["strong", "b"],
  italic: ["em", "i"],
  underline: ["u"],
  link: ["a"],
  list: ["ol", "li"],
  bullet: ["ul", "li"],
  mention: [],
  default: ["p", "span", "br"],
};

const allowedTags: string[] = [];

export const SANITIZE_HTML_OPTIONS: IOptions = {
  allowedTags: allowedTags.concat(...Object.values(FORMATS)),
  allowedAttributes: {
    a: ["href", "name", "target", "rel"],
    // attributes for expert mention
    span: ["data-type", "data-id"],
  },
};

export const getExpertMentionTemplate = (id: string) =>
  `<span data-type="expertMention" data-id="${id}">@${id}</span>`;
