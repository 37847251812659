import Parse from "parse";

import { MODEL_NAMES, SSO_METHOD } from "inexone-common/constants";
import type { ImageValue } from "inexone-common/definitions/Image";
import {
  getFullName,
  type UserDataAttributes,
} from "inexone-common/models/User";

import { IS_STORYBOOK } from "@/shared/constants";
import { getImageURL } from "@/shared/images";
import type Referral from "@/shared/models/Referral";

type UserAttributes = UserDataAttributes & {
  referral?: Referral;
  // We don't let the frontend know about defaultMembership, it doesn't need it
};

export default class User extends Parse.User<UserAttributes> {
  public getFullName = getFullName;

  static query(): Parse.Query<User> {
    const query = new Parse.Query(this);
    if (IS_STORYBOOK) {
      query.fromLocalDatastore();
    }
    return query;
  }

  static fromObject(json: Partial<ReturnType<User["toJSON"]>>): User {
    return User.fromJSON({
      className: MODEL_NAMES.USER,
      ...json,
    }) as User;
  }

  getIntercomHash(): string | undefined {
    return this.get("intercomHash");
  }

  getFullNameStrict(): string {
    const fullName = this.getFullName();
    return fullName ?? "";
  }

  getPhone(): string | undefined {
    return this.get("phone");
  }

  hasSSO(): boolean {
    return this.get("ssoMethod") !== SSO_METHOD.NONE;
  }

  getProfilePictureURL(size?: number): string | undefined {
    return User.getProfilePictureURL(this.get("profilePicture"), size);
  }

  static getProfilePictureURL(
    profilePicture?: ImageValue,
    size?: number,
  ): string | undefined {
    if (size) {
      return getImageURL(profilePicture, { width: size, height: size });
    }

    return getImageURL(profilePicture);
  }
}

Parse.Object.registerSubclass("_User", User);
