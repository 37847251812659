import type { ValuesOf } from "../types/utils";

/**
 * Services that providers support and projects require
 */
export const SurveyService = {
  DESIGN: "Survey Design",
  PROGRAMMING: "Programming",
  PANEL_RESPONDENTS: "Panel/Respondents",
  DATA_ANALYSIS: "Data Analysis",
  DATA_VISUALIZATION: "Data Visualization",
} as const;
export type SurveyService = ValuesOf<typeof SurveyService>;

/**
 * Market type that providers serve and projects target
 */
export const SurveyType = {
  B2B: "B2B",
  B2C: "B2C",
  HEALTHCARE: "Healthcare",
} as const;
export type SurveyType = ValuesOf<typeof SurveyType>;

/**
 * The states of a survey project.
 */
export enum SurveyState {
  /** This state is used to represent a draft survey project. */
  DRAFT = "draft",
  /** When a draft survey project is deleted before being published */
  DRAFT_DELETED = "draftDeleted",
  /** This state is used to represent a published survey project. */
  PUBLISHED = "published",
  /** This state is used to represent an archived survey project. */
  ARCHIVED = "archived",
  /** A special type of archived survey project that is to be ignored from analytics & lists */
  ARCHIVED_IGNORED = "archivedIgnored",
}

/** The status of a survey project while it's live (published) */
export enum SurveyStatus {
  /** Default live status. Providers should work on the project */
  IN_PROGRESS = "inProgress",
  /** Lets the client signal that the project is in pause */
  ON_HOLD = "onHold",
  /** Lets the client signal that no more work should ever be done on this survey project. */
  CLOSED = "closed",
}

/** Survey provider */

/** Represent the state of a Survey Provider in a specific project */
export enum SurveyProviderState {
  /** The provider was invited to the project */
  INVITED = "invited",
  /** The provider is working on an initial quote */
  PROVIDER_QUOTING = "providerQuoting",
  /** The provider has declined to work on this project. */
  PROVIDER_DECLINED = "providerDeclined",
  /** The client has declined the provider in this project. */
  CLIENT_DECLINED = "clientDeclined",
  /** The client has accepted the provider, work can start */
  CLIENT_ACCEPTED = "clientAccepted",
  /** The client requested for a final charge, provider to send final charge */
  FINALIZING = "finalizing",
  /** The client has accepted the final charge. This state is final */
  FINAL_CHARGE_ACCEPTED = "finalChargeAccepted",
}

export const SurveyProviderStateWeights: Record<SurveyProviderState, number> = {
  [SurveyProviderState.FINAL_CHARGE_ACCEPTED]: 6,
  [SurveyProviderState.FINALIZING]: 5,
  [SurveyProviderState.CLIENT_ACCEPTED]: 4,
  [SurveyProviderState.PROVIDER_QUOTING]: 3,
  [SurveyProviderState.INVITED]: 2,
  [SurveyProviderState.PROVIDER_DECLINED]: 1,
  [SurveyProviderState.CLIENT_DECLINED]: 0,
};

/** The status of a survey provider in a project while the project is live (published) */
export enum SurveyProviderStatus {
  QUOTING = "quoting",
  QUESTIONNAIRE_DESIGN = "questionnaireDesign",
  PROGRAMMING = "programming",
  FIELDING = "fielding",
}

export const providerStatusFriendlyName: Record<SurveyProviderStatus, string> =
  {
    [SurveyProviderStatus.FIELDING]: "Fielding",
    [SurveyProviderStatus.PROGRAMMING]: "Programming",
    [SurveyProviderStatus.QUESTIONNAIRE_DESIGN]: "Questionnaire design",
    [SurveyProviderStatus.QUOTING]: "Quoting",
  };

export enum SurveyInvoicingType {
  QUOTE = "quote",
  FINAL_CHARGE = "final_charge",
}

export enum SurveyInvoicingState {
  PENDING = "pending",
  ACCEPTED = "accepted",
}

export enum SurveyUserMembershipState {
  ACTIVE = "active",
  ACCESS_REMOVED = "access_removed",
}

/** Survey list views available to survey providers */
export enum SurveyProviderListView {
  ACTIVE = "active",
  CLOSED = "closed",
  DECLINED = "declined",
}
export const SurveyProviderDefaultListView = SurveyProviderListView.ACTIVE;

export enum SurveyClientListView {
  ACTIVE = "active",
  CLOSED = "closed",
}
export const SurveyClientDefaultListView = SurveyClientListView.ACTIVE;

export type SurveyListView = SurveyClientListView | SurveyProviderListView;

export const TargetRespondentType = {
  FIXED_NUMBER: "Fixed number",
  RANGE: "Range",
  MAX_FEASIBILITY: "Max feasibility",
} as const;

export type TargetRespondentType = ValuesOf<typeof TargetRespondentType>;
