// Just types
import type { ResizeDto as ExpressSharpOptions } from "express-sharp/dist/resize.dto";
import * as z from "zod";

export const DEMO_PREFIX = "demo_";
export const IMAGE_ENDPOINT_PATH = "/images";
export const AVATAR_SIZE = 480;

export const isDemoKey = (key: string) => key.includes(`/${DEMO_PREFIX}`);

export const ImageValueSchema = z.object({
  key: z.string(),
  width: z.number(),
  height: z.number(),
  format: z.string(),
});

export type ImageValue = z.infer<typeof ImageValueSchema>;

// see https://github.com/pmb0/express-sharp for options
type ImageOptions = {
  format?: ExpressSharpOptions["format"];
  height?: ExpressSharpOptions["height"];
  width?: ExpressSharpOptions["width"];
  quality?: ExpressSharpOptions["quality"];
  /**
   * Only for png or jpeg formats
   */
  progressive?: ExpressSharpOptions["progressive"];
  crop?: ExpressSharpOptions["crop"];
  gravity?: ExpressSharpOptions["gravity"];
};

/**
 * Generate a function that can return an image URL
 * Even though the prefix can point to a backend, in practice we always use a frontend
 * to leverage CDN caching
 *
 * @param {string} urlPrefix
 * @example
 * "https://app.inex.one"
 * "https://deploy-preview-1234--inexone.netlify.app"
 * "http://localhost:3000"
 *
 * @param {number} [pixelDensity]
 * When not specified, pixel density is chosen to be 2
 * (e.g. when coming from the backend for email images)
 */
export const getImageURLFunction =
  (urlPrefix: string, pixelDensity = 2) =>
  (image?: ImageValue, options: ImageOptions = {}): string | undefined => {
    if (!image) {
      return;
    }

    /**
     * `${"http://localhost:3000"}${"/images"}/${"profile/123456_avatar.png"}`
     */
    const imageURL = new URL(`${urlPrefix}${IMAGE_ENDPOINT_PATH}/${image.key}`);

    Object.entries(options)
      .filter(([, value]) => value !== undefined)
      .forEach(([key, value]) => {
        // "width" => "w", etc.
        const paramName = key[0];
        if (key === "width" || key === "height") {
          if (typeof value === "number") {
            const stringValue = Math.round(value * pixelDensity).toFixed(0);
            imageURL.searchParams.set(paramName, stringValue);
          }
        } else {
          imageURL.searchParams.set(paramName, value.toString());
        }
      });

    return imageURL.toString();
  };
