import SockJS from "sockjs-client";

/**
 * Custom WebSocket controller compatible with parse-SDK-JS's
 * [WebSocketController](https://github.com/parse-community/Parse-SDK-JS/blob/2.6.0/src/LiveQueryClient.js)
 * SockJS wants a server url with http(s)://, but parse has validation in
 * place that enforces ws(s)://
 *
 * @export
 * @class WebSocketController
 * @extends {SockJS}
 */
export default class WebSocketController extends SockJS {
  /**
   * Creates an instance of WebSocketController.
   *
   * @param {string} serverURL - Starts with "ws(s)://"
   * @memberof WebSocketController
   */
  constructor(serverURL: string) {
    const wsURL = new URL(serverURL);
    wsURL.protocol = wsURL.protocol.replace("ws", "http");
    super(wsURL.href);
  }
}
