import * as fVal from "inexone-common/validation/fieldValidation";

import { LOCAL_STORAGE_KEYS } from "@/shared/constants";

/** parse/lib/browser/StorageController.default */
const memMap: Record<string, string> = {};

const InMemoryController = {
  async: 0,
  getItem: (path: string): string | null => {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: imported from eslint
    if (memMap.hasOwnProperty(path)) {
      return memMap[path];
    }

    return null;
  },
  setItem: (path: string, value: string) => {
    memMap[path] = String(value);
  },
  removeItem: (path: string) => {
    delete memMap[path];
  },
  getAllKeys: () => Object.keys(memMap),
  clear: () => {
    for (const key in memMap) {
      // biome-ignore lint/suspicious/noPrototypeBuiltins: imported from eslint
      if (memMap.hasOwnProperty(key)) {
        delete memMap[key];
      }
    }
  },
} as const;

const LocalStorageController = {
  async: 0,
  getItem: (path: string): string | null => localStorage.getItem(path),
  setItem: (path: string, value: string) => {
    try {
      localStorage.setItem(path, value);
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
      // biome-ignore lint/suspicious/noConsoleLog: eslint migration
      console.log(e);
    }
  },
  removeItem: (path: string) => {
    localStorage.removeItem(path);
  },
  getAllKeys: () => {
    const keys: (string | null)[] = [];

    for (let i = 0; i < localStorage.length; i += 1) {
      keys.push(localStorage.key(i));
    }

    return keys;
  },
  clear: () => {
    localStorage.clear();
  },
} as const;

const storageAvailable = (): boolean => {
  try {
    const x = "__storage_test__";
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (_e) {
    return false;
  }
};

let StorageController: Storage;

if (storageAvailable()) {
  // @ts-expect-error
  StorageController = LocalStorageController;
} else {
  // @ts-expect-error
  StorageController = InMemoryController;
}

const jsonStorageSchemas = {
  [LOCAL_STORAGE_KEYS.PROJECT_LIST_SHOW_PRIMARY]: fVal.boolean(),
  [LOCAL_STORAGE_KEYS.CALL_MONITOR_SHOW_PRIMARY]: fVal.boolean(),
  [LOCAL_STORAGE_KEYS.PROJECT_CLOSE_REMINDER]: fVal.array(
    fVal.object({ requestId: fVal.string(), date: fVal.dateOrDateString() }),
  ),
  /** ThreadID > draft message */
  [LOCAL_STORAGE_KEYS.THREAD_DRAFTS]: fVal.record(fVal.string().optional()),
} as const;

type jsonStorageKeys = keyof typeof jsonStorageSchemas;

const LocalStorage = {
  ...StorageController,
  setJSONItem: <T extends jsonStorageKeys>(
    path: T,
    value: fVal.infer<(typeof jsonStorageSchemas)[T]>,
  ): void => {
    try {
      const schema = jsonStorageSchemas[path];
      schema.parse(value);

      const valueAsString = JSON.stringify(value);
      StorageController.setItem(path, valueAsString);
    } catch (e) {
      // biome-ignore lint/suspicious/noConsoleLog: eslint migration
      console.log("Failed to convert value to json sting", e);
    }
  },

  getJSONItem: <T extends jsonStorageKeys>(
    path: T,
  ): fVal.infer<(typeof jsonStorageSchemas)[T]> | undefined => {
    const valueAsString = StorageController.getItem(path);
    const schema = jsonStorageSchemas[path];

    if (valueAsString === null) {
      return;
    }

    try {
      const value = JSON.parse(valueAsString) as T;

      const safeValue = schema.parse(value);

      return safeValue;
    } catch (e) {
      // biome-ignore lint/suspicious/noConsoleLog: eslint migration
      console.log("Failed to convert value from string", e);

      StorageController.removeItem(path);
    }
  },
} as const;

export default LocalStorage;
