/**
 * The names of each Parse Class available for registration
 */
const MODEL_NAMES = {
  /** Defines an CSM account manager */
  ACCOUNT_MANAGER: "AccountManager",
  /** Defines a bill (composed of one or more ACCOUNT_TRANSACTION-s) */
  ACCOUNT_BILL: "AccountBill",
  /** Defines a financial transaction in the system */
  ACCOUNT_TRANSACTION: "AccountTransaction",
  /** Defines an attachment on a request or expert profile */
  ATTACHMENT: "Attachment",
  /** This model name is used to represent a business unit. */
  BUSINESS_UNIT: "BusinessUnit",
  /** This model name is used to represent an organization. */
  ORGANIZATION: "Organization",
  /** This model name is used to represent an organization's billing details. */
  ORG_BILLING_DETAILS: "OrgBillingDetails",
  /** This model name represents a client contract */
  CLIENT_CONTRACT: "ClientContract",
  /** Defines the default relation for an EN and all clients */
  DEFAULT_EN_CONTRACT: "DefaultENContract",
  /** This model name is used to represent a digest item.*/
  DIGEST_ITEM: "DigestItem",
  /** Defines the relation between a client and an EN. */
  CLIENT_EN_CONTRACT: "ClientENContract",
  /** used to track who invited a user to the platform */
  REFERRAL: "Referral",
  /** This model name is used to represent a request. */
  REQUEST: "Request",
  /** tracks request state changes */
  REQUEST_ACTION_LOG: "RequestActionLog",
  /** Represents a users participation in a request */
  REQUEST_USER: "RequestUser",
  /** tracks requestUser state changes */
  REQUEST_USER_ACTION_LOG: "RequestUserActionLog",
  /** Represents each user's personal relationship with a Request */
  USER_REQUEST: "UserRequest",
  /** Represents an organizations participation in a request */
  REQUEST_ENFIRM: "RequestENFirm",
  /** tracks requestENFirm state changes */
  REQUEST_ENFIRM_ACTION_LOG: "RequestENFirmActionLog",
  /** This model name is used to represent a global expert profile */
  EXPERT_CV: "ExpertCV",
  /* The career history of a global profile */
  EXPERT_CV_CAREER: "ExpertCVCareer",
  /* Notes messages associated to experts */
  EXPERT_NOTE: "ExpertNote",
  /** This model name is used to represent an expert profile. */
  EXPERT_PROFILE: "ExpertProfile",
  /* Maps a global expert profile's career history to a local expert profile */
  EXPERT_PROFILE_CAREER: "ExpertProfileCareer",
  /** tracks expert profile state changes */
  EXPERT_PROFILE_ACTION_LOG: "ExpertProfileActionLog",
  /** This model name is used to represent a booking. */
  BOOKING: "Booking",
  /** This model name is used to represent a completed expert call. */
  COMPLETED_EXPERT_CALL: "CompletedExpertCall",
  /** tracks booking state changes */
  BOOKING_ACTION_LOG: "BookingActionLog",
  /** This model name is used to represent a role. */
  ROLE: "_Role",
  /** This model name is used to represent a user. */
  USER: "_User",
  /** Defines policies that our users can approve */
  USER_POLICY: "UserPolicy",
  /** Defines approvals that users have made (not organizations) */
  USER_POLICY_APPROVAL: "UserPolicyApproval",
  /** This model name is used to represent a session. */
  SESSION: "_Session",
  /** This model name is used to represent a thread. */
  THREAD: "Thread",
  /** This model name is used to represent a thread message. */
  THREAD_MESSAGE: "ThreadMessage",
  /** This model name is used to represent an in-app notification. */
  IN_APP_NOTIFICATION: "InAppNotification",
  /** This model name is used to represent a call log */
  CALL_LOG: "CallLog",
  /** This model name is used to represent a migration */
  MIGRATION: "Migration",
  /** Represents a transcript */
  TRANSCRIPT: "Transcript",
  /** Used to validate jobs on adding to queue */
  JOB_HASH: "JobHash",
  /** Represents users' public relationship with their organization */
  ORG_MEMBERSHIP: "OrgMembership",
  ORG_MEMBERSHIP_ACTION_LOG: "OrgMembershipActionLog",

  /* Different angles tied to a request for larger projects */
  REQUEST_ANGLE: "RequestAngle",

  /* Vetting question tied to a request (optionally an angle) to be answered for experts */
  VETTING_QUESTION: "VettingQuestion",

  /* A survey project */
  SURVEY: "Survey",
  /* Quotes and final invoices in a survey project */
  SURVEY_INVOICING: "SurveyInvoicing",
  /* Organizations' permissions and attributes as a survey provider */
  SURVEY_PROVIDER: "SurveyProvider",
  /* Membership of each provider organization in a survey project */
  SURVEY_PROVIDER_MEMBERSHIP: "SurveyProviderMembership",
  /* Membership of each user in a given survey project */
  SURVEY_USER_MEMBERSHIP: "SurveyUserMembership",
} as const;
export default MODEL_NAMES;
