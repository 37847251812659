import { z } from "zod";

/** If we don't get this type, we don't export anything at all */
const logoVersions = z
  .object({
    full_dark: z.string(),
    full_light: z.string(),
    square_dark: z.string(),
    square_light: z.string(),
  })
  .partial();

const customLogoSchema = z
  .record(z.object({ default: z.string() }).transform((obj) => obj.default))
  .transform((record) => {
    const finalEntries: Record<string, Record<string, string>> = {};
    Object.entries(record).forEach(([key, value]) => {
      // Create a URL object to parse the path
      const url = new URL(key, "https://inex.one");
      const parts = url.pathname.split("/");
      // Get the logo name which is the last part of the path
      const logoName = parts[parts.length - 1];

      // The logos have a name like "{slug}.{style}.{"png"|"svg"}", e.g. "enco.full_dark.png"
      const match = logoName.match(/^(.+)\.(.+)\.(.+)$/);
      if (match) {
        const slug = match[1];
        const innerKey = match[2];

        if (!finalEntries[slug]) {
          finalEntries[slug] = {};
        }

        finalEntries[slug][innerKey] = value;
      }
    });
    return finalEntries;
  })
  .pipe(z.record(logoVersions));

/**
 *
 * Given the following files in this folder:
 * - enco.full_dark.png
 * - enco.full_light.png
 * - enco.square_light.png
 *
 * The resulting object looks like this:
 * {
 *  "enco": {
 *   full_dark: "https://app.inex.one/assets/abc123.png",
 *   full_light: "https://app.inex.one/assets/abc456.png",
 *   square_light: "https://app.inex.one/assets/abc789.png"
 *  }
 * }
 *
 * There's no chance the file is not found, because the glob is eager and the files are directly available as a URL
 * The data is also parsed by zod, so we know the versions we need are present
 */
const customLogos = customLogoSchema.parse(
  import.meta.glob("@/assets/logos/custom/*.{svg,png}", {
    /** Not an async call, the custom logos are directly available */
    eager: true,
    /** The modules will expose a URL, no base64 */
    query: "url",
  }),
);

export type LogoVersions = z.infer<typeof logoVersions>;

export default customLogos;
