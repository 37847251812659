import MODEL_NAMES from "./modelNames";

export enum FILE_CATEGORIES {
  EXCEL = "excel",
  POWER_POINT = "ppt",
  PDF = "pdf",
  TEXT = "text",
  IMAGE = "image",
  AUDIO = "audio",
}

export enum FILE_TYPES {
  TRANSCRIPT = "transcript",
  CV = "cv",
  CALL_SUMMARY = "callSummary",
  CALL_AUDIO = "callAudio",
  COMPLIANCE_VERIFICATION = "complianceVerification",
  PROJECT_BRIEF = "projectBrief",
  COMPLIANCE_REQUIREMENTS = "complianceRequirements",
  OTHER = "other",
}

export const FILE_TYPES_LABEL: Record<FILE_TYPES, string> = {
  [FILE_TYPES.TRANSCRIPT]: "Call transcript",
  [FILE_TYPES.CV]: "CV",
  [FILE_TYPES.CALL_SUMMARY]: "Call summary",
  [FILE_TYPES.CALL_AUDIO]: "Call audio",
  [FILE_TYPES.COMPLIANCE_VERIFICATION]: "Compliance verification",
  [FILE_TYPES.PROJECT_BRIEF]: "Project brief",
  [FILE_TYPES.COMPLIANCE_REQUIREMENTS]: "Compliance requirements",
  [FILE_TYPES.OTHER]: "Other",
} as const;

export const FILE_TYPES_MODEL = {
  [MODEL_NAMES.EXPERT_PROFILE]: [
    FILE_TYPES.CALL_SUMMARY,
    FILE_TYPES.CALL_AUDIO,
    FILE_TYPES.TRANSCRIPT,
    FILE_TYPES.COMPLIANCE_VERIFICATION,
    FILE_TYPES.CV,
    FILE_TYPES.OTHER,
  ],
  [MODEL_NAMES.REQUEST]: [
    FILE_TYPES.COMPLIANCE_REQUIREMENTS,
    FILE_TYPES.PROJECT_BRIEF,
    FILE_TYPES.OTHER,
  ],
  [MODEL_NAMES.THREAD]: [FILE_TYPES.OTHER],
  [MODEL_NAMES.SURVEY]: [FILE_TYPES.OTHER],
};

/**
 * Maximum file size in MB.
 *
 * Theoretical limit for HTTP PUT on S3 is 5GB.
 */
export const MAX_FILE_SIZE = 2600;

export const FILE_FORMATS: Record<string, FILE_CATEGORIES> = {
  ".xls": FILE_CATEGORIES.EXCEL,
  ".xlsx": FILE_CATEGORIES.EXCEL,
  ".csv": FILE_CATEGORIES.EXCEL,
  ".ods": FILE_CATEGORIES.EXCEL,
  ".tsv": FILE_CATEGORIES.EXCEL,

  ".ppt": FILE_CATEGORIES.POWER_POINT,
  ".pptx": FILE_CATEGORIES.POWER_POINT,
  ".odp": FILE_CATEGORIES.POWER_POINT,

  ".pdf": FILE_CATEGORIES.PDF,

  ".txt": FILE_CATEGORIES.TEXT,
  ".doc": FILE_CATEGORIES.TEXT,
  ".docx": FILE_CATEGORIES.TEXT,
  ".odt": FILE_CATEGORIES.TEXT,

  ".gif": FILE_CATEGORIES.IMAGE,
  ".jpeg": FILE_CATEGORIES.IMAGE,
  ".jpg": FILE_CATEGORIES.IMAGE,
  ".png": FILE_CATEGORIES.IMAGE,
  ".svg": FILE_CATEGORIES.IMAGE,

  // Audio in our case
  ".mp4": FILE_CATEGORIES.AUDIO,
  ".wav": FILE_CATEGORIES.AUDIO,
  ".m4a": FILE_CATEGORIES.AUDIO,
  ".mp3": FILE_CATEGORIES.AUDIO,
  ".mka": FILE_CATEGORIES.AUDIO,
  ".ogg": FILE_CATEGORIES.AUDIO,

  ".webm": FILE_CATEGORIES.AUDIO,
} as const;
