import { SSO_METHOD } from "inexone-common/constants";
import * as fValAsync from "inexone-common/validation/asyncFieldValidation";
import * as fVal from "inexone-common/validation/fieldValidation";

import type { _User as User } from "shared/models";

const DEFAULT_LOCALE = "en";

export const userShape = {
  email: fVal.email(),
  password: fVal.string(),
  username: fVal.email(),
  authData: fVal.any().optional(),
  firstName: fVal.name().optional(),
  lastName: fVal.name().optional(),
  phone: fValAsync.phone().optional(),
  intercomHash: fVal.textField().optional(),
  emailVerified: fVal.boolean().optional(),
  locale: fVal.textField().default(DEFAULT_LOCALE),
  disabled: fVal.boolean().default(false),
  timezone: fVal.timezone().optional(),
  profilePicture: fVal.imageValue().optional(),
  referralTime: fVal.date().optional(),
  ssoMethod: fVal.nativeEnum(SSO_METHOD).default(SSO_METHOD.NONE),
  lastSeenAt: fVal.date().optional(),
};

const userSchema = fVal.object(userShape);

export type UserDataAttributes = fVal.infer<typeof userSchema>;

export function getFullName(this: User): string | undefined {
  const firstName = this.get("firstName");
  const lastName = this.get("lastName");

  if (!(firstName && lastName)) {
    return;
  }

  return `${firstName} ${lastName}`;
}
