/**
 * User Roles
 *
 * This does NOT relate to user roles defined in DB!
 */
export enum USER_ROLE_NAMES {
  CUSTOMER = "customer",
  CONTRACTOR = "contractor",
}

/**
 * External User Types
 *
 * Currently only used for certain notifications but probably it
 * will be the future state where also experts may have accounts.
 * Name change to user type which is also likely that role names will
 * change to as the current name is confusing with parse/roles.
 */
export enum EXT_USER_TYPES {
  /**
   * Inex' customer (has a User entity in DB)
   */
  CUSTOMER = "customer",

  /**
   * Inex' EN User (has a User entity in DB)
   */
  CONTRACTOR = "contractor",

  /**
   * Expert User (does NOT have a User entity in DB!)
   */
  EXPERT = "expert",
}
