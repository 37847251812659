import { isLocalhost } from "@/helpers/utils";
import { FRONTEND_CHECK_STALE } from "@/shared/constants";

/**
 * In-memory cache of the frontend's index.html etag
 */
let currentIndex: string | undefined;
/**
 * ms since epoch
 */
let lastCheck: number | undefined;

let needsRefresh = false;

const setNeedsRefresh = (value: boolean): void => {
  needsRefresh = value;
};
export const getNeedsRefresh = (): boolean => needsRefresh;

const isNewVersionAvailable = async (): Promise<boolean> => {
  if (isLocalhost) {
    return false;
  }
  const currentTime = new Date().getTime();
  if (!lastCheck || currentTime - lastCheck > FRONTEND_CHECK_STALE) {
    try {
      const res = await fetch("/index.html");
      const newIndex = await res.text();
      if (currentIndex && currentIndex !== newIndex) {
        return true;
      }
      currentIndex = newIndex;
      lastCheck = currentTime;
    } catch (_error) {
      // Network error, let's check again soon
    }
  }
  return false;
};

/**
 * Calls `/index.html`. If etag changed, some action can be done
 *
 * Netlify doesn't guarantee that the `etag` header will be the same
 * for the same file. It guarantees that the `etag` won't change as long as the
 * remote that is reached is unchanged. If the DNS query sends the client to
 * a different remote after some time, the etag may very well be different.
 *
 * In a local environment, etags are consistent but index.html doesn't change
 *
 * As long as we keep `index.html` rather small in production (+minified),
 * comparing strings is good enough.
 */
export default async function checkFrontendVersion(): Promise<void> {
  if (await isNewVersionAvailable()) {
    setNeedsRefresh(true);
  }
}
