import type { Search } from "history";
import { matchPath } from "react-router-dom";

import PATHS, { IGNORE_USER_PATHS } from "inexone-common/constants/paths";
import type { Workspace } from "inexone-common/types/schemas/workspaces";
import type {
  AuthorityValue,
  FeatureValue,
  PolicyValue,
} from "inexone-common/utils/authAndFeatures";

import localStorage from "@/helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "@/shared/constants";
import history from "@/shared/history";
import type { _User as User } from "@/shared/models";

const { USER, SIGNED_IN_USER, DEFAULT } = PATHS;

export type UserAndAuth = {
  user: User | undefined;
  currentWorkspace: Workspace | undefined;
  workspaces: Workspace[];
  authorityList: AuthorityValue[];
  featureList: FeatureValue[];
  policiesList: PolicyValue[];
};

const redirectIfRequired = (
  currentPath: string,
  redirectPath: string,
  search?: Search,
): void => {
  if (currentPath !== redirectPath) {
    history.push({ pathname: redirectPath, search });
    return;
  }
};

const redirectLoggedInUser = (
  { policiesList, authorityList }: UserAndAuth,
  currentPath: string,
  initialized: boolean,
) => {
  if (matchPath(currentPath, { path: USER.BECOME }) && initialized === false) {
    /**
     * Become uses LOCAL_STORAGE_KEYS.REDIRECT_PATH to redirect below.
     *
     * On first app load (initialized === false), if the path is on Become and
     * if there is an existing sessionToken, we don't want the initAuthState()
     * to redirect away from the Become page before it can run User.become().
     * So we have to ignore and wait for becomeUser to run redirectOnAuth by itself.
     */
    return;
  }

  if (!authorityList.includes("emailVerified")) {
    return redirectIfRequired(currentPath, SIGNED_IN_USER.VERIFY_EMAIL);
  }
  if (!authorityList.includes("profileCompleted")) {
    return redirectIfRequired(currentPath, SIGNED_IN_USER.COMPLETE_PROFILE);
  }
  if (
    !(
      policiesList.includes("privacyPolicy") &&
      policiesList.includes("tncPolicy")
    )
  ) {
    return redirectIfRequired(currentPath, SIGNED_IN_USER.ACCEPT_POLICY);
  }

  const isOnAccountActionPage =
    currentPath.startsWith(USER.ROOT) ||
    currentPath.startsWith(SIGNED_IN_USER.ROOT);

  // If the user is logged in, and passes preceding checks then there is
  // no need to remain on pages that facilitate account actions
  if (isOnAccountActionPage) {
    const redirectPath = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH);
    if (!redirectPath) {
      return redirectIfRequired(currentPath, DEFAULT.ROOT);
    }

    const search =
      localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_SEARCH) || undefined;
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_SEARCH);
    return redirectIfRequired(currentPath, redirectPath, search);
  }

  // No need to redirect on any other page
  return;
};

const redirectVoidUser = (currentPath: string) => {
  if (currentPath.startsWith(USER.ROOT)) {
    return;
  }
  if (currentPath.startsWith(SIGNED_IN_USER.ROOT)) {
    return redirectIfRequired(currentPath, USER.LOGIN);
  }

  const search = window.location.search;
  localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH, currentPath);
  localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_SEARCH, search);
  return redirectIfRequired(currentPath, USER.LOGIN);
};

const redirectOnAuthState = (
  userAndAuth: UserAndAuth | undefined,
  initialized: boolean,
): void => {
  const currentPath = window.location.pathname;
  if (IGNORE_USER_PATHS.some((path) => matchPath(currentPath, { path }))) {
    return;
  }

  const user = userAndAuth?.user;
  const currentWorkspace = userAndAuth?.currentWorkspace;

  if (!(user && currentWorkspace)) {
    redirectVoidUser(currentPath);
    return;
  }

  redirectLoggedInUser(userAndAuth, currentPath, initialized);
  return;
};

export default redirectOnAuthState;
