import { generatePath } from "react-router-dom";

import {
  EXPERT_BASE_PATH,
  EXPERT_PROFILE_STATES,
  MOMENT_FORMATS,
} from "inexone-common/constants";
import type {
  ComplianceAnswers,
  ExpertProfileAttributes,
} from "inexone-common/models/ExpertProfile";
import type { ComplianceRequirement } from "inexone-common/models/utils/RequestPreferences";
import { getDateRangeString } from "inexone-common/utils/date";

import type { ExpertCvExperience } from "../types/schemas/expertCVCareer";
import type { ExpertProfileExperience } from "../types/schemas/expertProfileCareer";

export const isDraftDeleted = (state: EXPERT_PROFILE_STATES): boolean => {
  return state === EXPERT_PROFILE_STATES.DRAFT_DELETED;
};

export const isDraft = (state: EXPERT_PROFILE_STATES): boolean => {
  return state === EXPERT_PROFILE_STATES.DRAFT;
};

export const isPublished = (state: EXPERT_PROFILE_STATES): boolean => {
  return state === EXPERT_PROFILE_STATES.PUBLISHED;
};

export const isDeclined = (state: EXPERT_PROFILE_STATES): boolean => {
  return state === EXPERT_PROFILE_STATES.DECLINED;
};

export function getFrontEndLink(expertId: string, requestId: string): string {
  return generatePath(EXPERT_BASE_PATH.ROOT, {
    requestId,
    expertId,
  });
}

export const getTitle = (
  experience: ExpertProfileExperience | ExpertCvExperience | undefined,
) => {
  if (experience?.endDate) {
    return `Former ${experience.title}`;
  }

  return experience?.title ?? "[Title]";
};

export const getTitleFromPrimary = (
  pastExperiences: ExpertProfileExperience[],
): string => {
  const primaryExperience = getPrimaryExperience(pastExperiences);
  return getTitle(primaryExperience);
};

export const getCompany = (
  experience: ExpertProfileExperience | ExpertCvExperience | undefined,
) => {
  return experience?.company ?? "[Company name]";
};

export const getCompanyFromPrimary = (
  pastExperiences: ExpertProfileExperience[],
): string => {
  return getCompany(getPrimaryExperience(pastExperiences));
};

export const getNameTitleAndCompany = (
  title: string,
  company: string,
  name?: string,
): string => {
  const titleAndCompany = `${title} at ${company}`;

  if (!name) {
    return titleAndCompany;
  }
  return `${name} (${titleAndCompany})`;
};

export const getSubtitle = (name?: string, company?: string): string => {
  return [name, company].filter(Boolean).join(" ⦁ ");
};

export const getPrimaryExperience = (
  pastExperiences: ExpertProfileExperience[],
): ExpertProfileExperience | undefined => {
  return pastExperiences.find((exp) => exp.primary === true);
};

export const getExperienceDateString = (
  startDate: Date,
  endDate: Date | undefined,
): string => {
  return getDateRangeString({
    startDate,
    endDate: endDate ?? "Present",
    format: MOMENT_FORMATS.MONTH_YEAR,
  });
};

export const getExperienceDateStringFromPrimary = (
  pastExperiences: ExpertProfileExperience[],
): string | undefined => {
  const primaryExperience = getPrimaryExperience(pastExperiences);

  if (primaryExperience) {
    return getExperienceDateString(
      primaryExperience.startDate,
      primaryExperience.endDate,
    );
  }
};

// count a time slot as future if it ends at least 1.5 hours from now
const FUTURE_TIME_SLOT_END = 1.5 * 60 * 60 * 1000;

export const expertHasFutureAvailabilities = (
  availability: {
    start: Date;
    end: Date;
  }[],
) => {
  const now = new Date().getTime();

  return availability.some(
    (availability) =>
      availability.end &&
      availability.end.getTime() - FUTURE_TIME_SLOT_END > now,
  );
};

/**
 * Returns number of answers to compliance which are "incorrect", i.e.
 * true or undefined
 */
export const getCorrectExpertComplianceCount = (
  complianceRequirements: ComplianceRequirement[],
  complianceAnswers: ComplianceAnswers = {},
): [number, number] => {
  const totalCount = complianceRequirements.length;

  const hasCompliance = totalCount > 0;
  if (!hasCompliance) {
    return [0, 0];
  }

  const { answers } = complianceAnswers;

  if (!answers) {
    return [0, totalCount];
  }

  let successCount = 0;
  complianceRequirements.forEach(({ id }) => {
    /** We only write questions for which no is passing */
    if (answers[id] === false) {
      successCount += 1;
    }
  });

  return [successCount, totalCount];
};

export const compareExperiences = <TExperience extends ExpertCvExperience>(
  a: TExperience,
  b: TExperience,
): number => {
  const aStartDate = a.startDate.getTime();
  const bStartDate = b.startDate.getTime();

  const aEndDate = (a.endDate ?? new Date()).getTime();
  const bEndDate = (b.endDate ?? new Date()).getTime();

  if (aEndDate !== bEndDate) {
    return bEndDate - aEndDate;
  }

  if (aStartDate !== bStartDate) {
    return bStartDate - aStartDate;
  }

  return a.title.localeCompare(b.title);
};

export const getDecliningTimeAndUser = (
  state: ExpertProfileAttributes["state"],
  stateChanges: ExpertProfileAttributes["stateChanges"],
) => {
  if (state !== EXPERT_PROFILE_STATES.DECLINED) {
    return { declinedAt: undefined, declinedBy: undefined };
  }
  const lastState = stateChanges?.at(-1);

  if (!lastState || lastState.toState !== EXPERT_PROFILE_STATES.DECLINED) {
    return { declinedAt: undefined, declinedBy: undefined };
  }

  return { declinedAt: lastState.time, declinedBy: lastState.userId };
};
