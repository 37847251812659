import { MODEL_NAMES } from "inexone-common/constants";
import type { OrgBillingDetailsAttributes } from "inexone-common/models/OrgBillingDetails";
import type * as ApiDelete from "inexone-common/types/apiFunctions/orgBillingDetails/delete";
import type * as ApiEdit from "inexone-common/types/apiFunctions/orgBillingDetails/edit";
import type * as ApiList from "inexone-common/types/apiFunctions/orgBillingDetails/list";

import { cloudCode, forgetAboutObject } from "@/shared/parseHelpers";

import ParseObject from "./utils/ParseObject";

export default class OrgBillingDetails extends ParseObject<OrgBillingDetailsAttributes>(
  MODEL_NAMES.ORG_BILLING_DETAILS,
) {
  public static list(): Promise<ApiList.ReturnType> {
    return cloudCode("orgBillingDetails_list", {});
  }

  public static edit(
    id: string,
    billingDetailsParams: ApiEdit.Params["billingDetailsParams"],
  ): Promise<ApiEdit.ReturnType> {
    forgetAboutObject(OrgBillingDetails.createWithoutData(id));
    return cloudCode("orgBillingDetails_edit", {
      id,
      billingDetailsParams,
    });
  }

  public static delete(id: string): Promise<ApiDelete.ReturnType> {
    return cloudCode("orgBillingDetails_delete", { id });
  }
}
