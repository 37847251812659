import windowLocation from "@/shared/windowLocation";

/**
 * This file exports Parse-related config: Server URLs and application Ids
 */

export const applicationId = import.meta.env.VITE_PARSE_APP_ID as string;
export const parseServerUrl = import.meta.env.VITE_PARSE_SERVER_URL as string;
/**
 * By default, the LQ server endpoint is the current page's base with the /socket path.
 * Is overriden by an ENV variable in production as long as Netlify's CDN doesn't support WSS forwarding
 * We then connect directly to the backend with no proxying
 */
export const liveQueryServerUrl =
  import.meta.env.VITE_PARSE_LIVEQUERY_SERVER_URL ||
  new URL("/socket", windowLocation.href).href;
