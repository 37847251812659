import { BOOKING_STATES, MODEL_NAMES } from "inexone-common/constants";
import type { BookingAttributes } from "inexone-common/models/Booking";
import type { BaseCalendarEvent } from "inexone-common/types/Calendar";

import ParseObject from "./utils/ParseObject";

export default class Booking extends ParseObject<BookingAttributes>(
  MODEL_NAMES.BOOKING,
) {
  static isRequested(state: BOOKING_STATES): boolean {
    return state === BOOKING_STATES.REQUESTED;
  }

  static isScheduled(state: BOOKING_STATES): boolean {
    return state === BOOKING_STATES.SCHEDULED;
  }

  static isRescheduleRequested(state: BOOKING_STATES): boolean {
    return state === BOOKING_STATES.RESCHEDULE_REQUESTED;
  }
  static isCanceled(state: BOOKING_STATES): boolean {
    return state === BOOKING_STATES.CANCELED;
  }

  static isCompleted(state: BOOKING_STATES): boolean {
    return state === BOOKING_STATES.COMPLETED;
  }

  static isBooked(state: BOOKING_STATES): boolean {
    return !Booking.isRequested(state) && !Booking.isCanceled(state);
  }

  /**
   * This won't throw as long as the booking isn't "requested"
   */
  static getBookingStartStrict(bookingStart?: Date): Date {
    if (!bookingStart) {
      throw new Error("No booking start");
    }
    return bookingStart;
  }

  static getBookingEndStrict(bookingEnd?: Date): Date {
    if (!bookingEnd) {
      throw new Error("No booking end");
    }
    return bookingEnd;
  }

  static getBookingRange(
    state: BOOKING_STATES,
    bookingStart?: Date,
    bookingEnd?: Date,
  ): BaseCalendarEvent | undefined {
    if (Booking.isBooked(state)) {
      return {
        start: Booking.getBookingStartStrict(bookingStart),
        end: Booking.getBookingEndStrict(bookingEnd),
      };
    }

    return undefined;
  }
}
