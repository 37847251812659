import { generatePath } from "react-router-dom";

import { MODEL_NAMES, PATHS } from "inexone-common/constants";
import type { ThreadAttributes } from "inexone-common/models/Thread";
import type * as ApipostMessage from "inexone-common/types/apiFunctions/thread/postMessage";
import type { FullRequest } from "inexone-common/types/apiFunctions/request/view";

import type { Organization, ThreadMessage } from "@/shared/models";
import { cloudCode } from "@/shared/parseHelpers";

import ParseObject from "./utils/ParseObject";

export default class Thread extends ParseObject<ThreadAttributes>(
  MODEL_NAMES.THREAD,
) {
  getENFirmOrg(): Organization | undefined {
    return this.get("enFirmOrg");
  }

  getLastComment(): ThreadMessage | undefined {
    return this.get("lastComment");
  }

  getCommentCount(): number {
    return this.get("commentCount");
  }

  getRequestPartnerOrgIdAndName(
    isCustomer: boolean,
    request: FullRequest,
  ): { id?: string; name?: string } {
    return isCustomer
      ? {
          id: this.get("enFirmOrg")?.id,
          name: this.get("enFirmOrg")?.get("name"),
        }
      : { id: request.customerOrgId, name: request.customerOrgName };
  }

  static queryExpertNote(): Parse.Query<Thread> {
    return Thread.query().equalTo("targetClass", MODEL_NAMES.EXPERT_PROFILE);
  }

  /**
   * Out of an expert id, returns a new query that returns all threads
   * related to that expert profile
   */
  static queryExpertNotes(expertId: string): Parse.Query<Thread> {
    if (!expertId) {
      return Thread.dummyQuery();
    }

    return Thread.queryExpertNote().equalTo("targetId", expertId);
  }

  /**
   * Post a message to multiple threads
   */
  static postMessage(
    params: ApipostMessage.Params,
  ): Promise<ApipostMessage.ReturnType> {
    return cloudCode("thread_postMessage", params);
  }

  /**
   * Out of a request, returns a new query that returns all threads
   * related to that request
   */
  static queryRequestList(requestId: string): Parse.Query<Thread> {
    const query = Thread.query()
      .equalTo("targetClass", MODEL_NAMES.REQUEST)
      .equalTo("targetId", requestId)
      .include(["lastComment", "enFirmOrg", "lastComment.sender"]);

    return query;
  }

  /**
   * Select team thread and common threads.
   * Sort common threads by last comment date or by the name of their ENFirmOrg
   */
  static groupRequestThreads(threads: Thread[]): {
    commonThreads: Thread[];
    teamThread?: Thread;
  } {
    const commonThreads = threads
      .filter(
        (thread) =>
          !thread.get("isPrivate") &&
          Boolean(thread.getENFirmOrg()?.get("name")),
      )
      .sort((a, b) => {
        const aLast = a.getLastComment();
        const bLast = b.getLastComment();
        const aENFirm = a.getENFirmOrg();
        const bENFirm = b.getENFirmOrg();

        // sort alphabetically if both have no messages
        if (!aLast && !bLast && aENFirm && bENFirm) {
          return aENFirm.get("name").localeCompare(bENFirm.get("name"));
        }

        /** See https://sentry.io/organizations/inexone/issues/2036771530/?project=1509174  */
        if (!aLast?.getSentAt?.()) return 1;
        if (!bLast?.getSentAt?.()) return -1;

        return bLast.getSentAt().getTime() - aLast.getSentAt().getTime();
      });

    const teamThread = threads.find((thread) => thread.get("isPrivate"));

    return { commonThreads, teamThread };
  }

  static getRequestPath(requestId: string, organizationId?: string): string {
    return generatePath(PATHS.DEFAULT.REQUESTS.REQUEST.CHAT, {
      requestId,
      organizationId,
    });
  }

  static getSurveyPath(surveyId: string, threadId: string): string {
    return generatePath(PATHS.DEFAULT.SURVEYS.VIEW.CHAT, {
      surveyId,
      threadId: threadId,
    });
  }

  static getRequestRootPath(requestId: string): string {
    return this.getRequestPath(requestId);
  }

  static getRequestTeamPath(requestId: string): string {
    return this.getRequestPath(requestId, "team");
  }
}
