import React from "react";

/**
 * Helper class to ensure a context is not re-rendered every time the
 * context object changes.
 */

type Props<T> = {
  value: T;
  Provider: React.Provider<T>;
  children: React.ReactNode;
};

class ObjectContext<
  T extends Record<string, unknown> | undefined,
> extends React.Component<Props<T>, T> {
  constructor(props: Props<T>) {
    super(props);
    this.state = props.value;
  }

  static getDerivedStateFromProps<
    T extends Record<string, unknown> | undefined,
  >({ value }: Props<T>, state: Props<T>["value"]): T | null {
    if (value === undefined) {
      return null;
    }

    const keys = Object.keys(value);
    const updateData = keys.some(
      (key) => state === undefined || value[key] !== state[key],
    );

    if (updateData) {
      // if you want to print for debugging
      // Object.keys(value).forEach((key) => {
      //   if (state === undefined || value[key] !== state[key]) {
      //     console.log(key);
      //   }
      // });

      return value;
    }

    return null;
  }

  render(): JSX.Element {
    const { Provider, children } = this.props;
    const value = this.state;

    return <Provider value={value}>{children}</Provider>;
  }
}

export default ObjectContext;
