import Parse from "parse";

import { applicationId, liveQueryServerUrl } from "@/shared/parseConfig";

/**
 * Creates a new instance of LiveQueryClient, takes care of protocol replacement
 * Exports it to be used across the app
 *
 * @module liveQueryClient A Parse LiveQueryClient instance
 */

const sockJSUrl = new URL(liveQueryServerUrl);
sockJSUrl.protocol = sockJSUrl.protocol.replace("http", "ws");

// @ts-expect-error
const liveQueryClient = new Parse.LiveQueryClient({
  applicationId,
  serverURL: sockJSUrl.href,
});

export default liveQueryClient;
