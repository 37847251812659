import { CONFERENCE_TYPE, MODEL_NAMES } from "inexone-common/constants";
import {
  EventType,
  type CallLogAttributes,
} from "inexone-common/models/CallLog";
import { getParticipantsInRoom } from "inexone-common/utils/callParticipantStatus";

import Booking from "./Booking";
import ParseObject from "./utils/ParseObject";

export default class CallLog extends ParseObject<CallLogAttributes>(
  MODEL_NAMES.CALL_LOG,
) {
  /**
   * The call logs should be normalized centrally but for now the following
   * is the best way to consistently get if its dial in etc. out:
   *
   * twilio - web:
   * - participanName: name chosen by user
   * - participantPhoneNumber: undefined
   * - participantRole: customer/contractor
   *
   * twilio - phone:
   * - participanName: Dial in ({country})
   * - participantPhoneNumber: phone number
   * - participantRole: customer/contractor
   *
   * zoom - web:
   * - participanName: name chosen by user
   * - participantPhoneNumber: undefined
   * - participantRole: undefined
   *
   * zoom - phone:
   * - participanName: phone number (redacted, 4678****4343)
   * - participantPhoneNumber: undefined
   * - participantRole: undefined
   */
  getParticipantLabel(): string {
    const participantName = this.get("participantName") ?? "";
    const isTwilio = this.get("meetingType") === CONFERENCE_TYPE.TWILIO;

    let phoneText: string | undefined;

    if (isTwilio) {
      phoneText = this.get("participantPhoneNumber") ?? undefined;
    } else {
      phoneText =
        participantName.indexOf("***") !== -1 ? participantName : undefined;
    }

    const body = phoneText ? "Dial in" : participantName;
    const expertText =
      this.get("participantRole") === "contractor" ? "expert" : undefined;

    const extras = [phoneText, expertText].filter((v) => Boolean(v));

    if (extras.length > 0) {
      return `${body} (${extras.join(" - ")})`;
    }

    return body;
  }
  static callIsOngoing(sortedCallLogs: CallLog[]): boolean {
    const logsLength = sortedCallLogs.length;
    const participantsInRoom = getParticipantsInRoom(sortedCallLogs);
    const roomIsClosed =
      sortedCallLogs[logsLength - 1].get("eventType") ===
      EventType.MeetingEnded;

    return !(roomIsClosed || participantsInRoom.length === 0);
  }

  static getDisplayedEvents(): CallLogAttributes["eventType"][] {
    return [
      EventType.MeetingStarted,
      EventType.MeetingEnded,
      EventType.ParticipantJoined,
      EventType.ParticipantLeft,
      EventType.RecordingStarted,
    ];
  }

  static queryFromBooking(bookingId?: string): Parse.Query<CallLog> {
    if (!bookingId) {
      return CallLog.dummyQuery();
    }

    return CallLog.query()
      .equalTo("booking", bookingId)
      .containedIn("eventType", CallLog.getDisplayedEvents())
      .ascending("date");
  }

  static queryOngoingCall(
    bookingId: string,
    conferenceType: CONFERENCE_TYPE,
  ): Parse.Query<CallLog> {
    // if manual there will be no logs
    if (conferenceType === CONFERENCE_TYPE.MANUAL) {
      return CallLog.dummyQuery();
    }

    return CallLog.query()
      .equalTo("booking", Booking.createWithoutData(bookingId))
      .equalTo("meetingType", conferenceType)
      .containedIn("eventType", [
        EventType.ParticipantJoined,
        EventType.ParticipantLeft,
      ])
      .ascending("date");
  }
}
