/**
 * This file should not generally be used, instead use helpers/time/timezone.
 *
 * Its purpose is to provide the timezone name while avoiding to load moment-timezone.
 * For most browsers we support except ie11 Intl works and we can use that (moment does too)
 *
 */

const getTimezoneName = async (): Promise<string> => {
  let name: string | undefined;
  try {
    name = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (_e) {
    // Intl doesnt exist
  }

  if (name) {
    return name;
  }

  const moment = await import("moment-timezone");

  return moment.tz.guess();
};

export default getTimezoneName;
