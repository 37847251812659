/**
 * Decoding results from the parse-server API
 *
 * See https://github.com/parse-community/Parse-SDK-JS/blob/3551a06a94de60e945e2da780cf4a3b3201838da/src/decode.js#L19
 * for the original source. Given it's meant to be Parse-free in the frontend, all it does is decoding dates
 */
// biome-ignore lint/suspicious/noExplicitAny: eslint migration
export const decode = (value: any): any => {
  if (value === null || typeof value !== "object" || value instanceof Date) {
    return value;
  }
  if (Array.isArray(value)) {
    // biome-ignore lint/suspicious/noExplicitAny: eslint migration
    const dup: any[] = [];
    value.forEach((v, i) => {
      dup[i] = decode(v);
    });
    return dup;
  }
  if (value.__type === "Date") {
    return new Date(value.iso);
  }
  // biome-ignore lint/suspicious/noExplicitAny: eslint migration
  const copy: any = {};
  for (const k in value) {
    copy[k] = decode(value[k]);
  }
  return copy;
};

/**
 * Encoding results for the parse-server API
 *
 * See https://github.com/parse-community/Parse-SDK-JS/blob/3551a06a94de60e945e2da780cf4a3b3201838da/src/decode.js#L19
 * for the original source. Given it's meant to be Parse-free in the frontend, all it does is encoding dates
 */
// biome-ignore lint/suspicious/noExplicitAny: eslint migration
export const encode = (value: any): any => {
  if (Object.prototype.toString.call(value) === "[object Date]") {
    if (Number.isNaN(value)) {
      throw new Error("Tried to encode an invalid date.");
    }
    return { __type: "Date", iso: (value as Date).toJSON() };
  }

  if (Array.isArray(value)) {
    return value.map((v) => {
      return encode(v);
    });
  }

  if (value && typeof value === "object") {
    // biome-ignore lint/suspicious/noExplicitAny: eslint migration
    const output: any = {};
    for (const k in value) {
      output[k] = encode(value[k]);
    }
    return output;
  }

  return value;
};
