/**
 * See https://github.com/trpc/trpc/blob/1a81eeafe7344759e715704ba7e7b41a1c32e792/packages/server/src/shared/createProxy/index.ts
 */
interface ProxyCallbackOptions {
  path: string[];
  args: unknown[];
}
type ProxyCallback = (opts: ProxyCallbackOptions) => unknown;

const noop = () => {
  // noop
};

function createInnerProxy(callback: ProxyCallback, path: string[]) {
  const proxy: unknown = new Proxy(noop, {
    get(_obj, key) {
      if (typeof key !== "string" || key === "then") {
        // special case for if the proxy is accidentally treated
        // like a PromiseLike (like in `Promise.resolve(proxy)`)
        return undefined;
      }
      /* One level deeper in the path */
      return createInnerProxy(callback, [...path, key]);
    },
    /** When the proxy is called */
    apply(_1, _2, args) {
      return callback({
        // Arguments used to call the function
        args,
        // Current path
        path,
      });
    },
  });

  return proxy;
}

/**
 * Creates a proxy that calls the callback with the path and arguments
 *
 * @example
 * ```ts
 *
 * const callback = ({path, args}) => {
 *   // I have a access to "path" and "args"
 * }
 *
 * const proxy = createProxy(callback);
 *
 * proxy.a.b.c(1, 2, 3) -> callback is called with { path: ["a", "b", "c"], args: [1, 2, 3] }
 *
 * ```
 */
export const createRecursiveProxy = (callback: ProxyCallback) =>
  createInnerProxy(callback, []);
