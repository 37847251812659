import React from "react";
import { useMediaQuery } from "react-responsive";

import ObjectContext from "@/helpers/ObjectContext";
import { MEDIA_QUERY_OPTIONS, MINWIDTH_MEDIA_SIZES } from "@/shared/constants";

type MediaQueries = Record<MEDIA_QUERY_OPTIONS, boolean>;

const ScreenSizeContext = React.createContext<MediaQueries>({
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_MOBILE]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_SMALL_TABLET]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_TABLET]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_SMALL_DESKTOP]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_DESKTOP]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_LARGE_DESKTOP]: false,
  [MEDIA_QUERY_OPTIONS.MIN_WIDTH_PANEL_WITH_SIDE_FILTERS]: false,
});

const ScreenSizeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  // @ts-expect-error we build the object below
  const mediaQueries: MediaQueries = {};

  Object.values(MEDIA_QUERY_OPTIONS).forEach(function MediaQueryCreate(name) {
    const query = `(min-width: ${MINWIDTH_MEDIA_SIZES[name]}px)`;
    // biome-ignore lint/correctness/useHookAtTopLevel: fine in a provider
    mediaQueries[name] = useMediaQuery({ query });
  });

  return (
    <ObjectContext value={mediaQueries} Provider={ScreenSizeContext.Provider}>
      {children}
    </ObjectContext>
  );
};

const useScreenSizeContext = (): MediaQueries => {
  const context = React.useContext(ScreenSizeContext);

  if (context === undefined) {
    throw new Error(
      "useScreenSizeContext must be used within a ScreenSizeProvider",
    );
  }
  return context;
};

export { ScreenSizeProvider, useScreenSizeContext };
