import { Suspense } from "react";
import { Redirect, Route, Switch, type RedirectProps } from "react-router-dom";

import type {
  AuthorityInputValue,
  AuthorityMatchesFunc,
  FeatureInputValue,
  HasFeatureFunc,
} from "inexone-common/utils/authAndFeatures";

import useAuth from "@/hooks/useAuth";

import CenteredSpinner from "@/components/Progress/CenteredSpinner";

import ErrorBoundary from "./ErrorBoundary";
import Exception from "./Exception/Exception";

const pageNotFound = <Exception type="404" />;
const unauthorized = <Exception type="403" />;

type SwitchRoute<T extends Record<string, unknown> = Record<string, unknown>> =
  {
    // Describes the authority for this route. Can be much more than a string/array
    authority?: AuthorityInputValue;
    feature?: FeatureInputValue;
    // react component to be rendered at this route
    Component:
      | React.ComponentType
      | React.LazyExoticComponent<React.ComponentType>;
    // Optional key to distinguish routes
    key?: string;
    // Nested children of switch
    routes?: SwitchChild<T>[];
    redirect?: false;
    path?: string | string[];
    exact?: boolean;
    strict?: boolean;
  } & T;

interface SwitchRedirectExtra {
  redirect: true;
  key?: string;
}

const AuthorizeRoute = <
  T extends Record<string, unknown> = Record<string, unknown>,
>({
  route,
  authorize,
  children,
  authorityMatches,
  hasFeature,
}: {
  route: SwitchChild<T>;
  authorize?: (args: T) => boolean;
  children: React.ReactElement;
  authorityMatches: AuthorityMatchesFunc;
  hasFeature: HasFeatureFunc;
}) => {
  if (authorize) {
    if (!authorize(route)) {
      return unauthorized;
    }
  }

  // unsure why typing is lost in this function
  const authority = (route.authority ?? []) as AuthorityInputValue;
  const feature = route.feature as FeatureInputValue;

  return authorityMatches(...authority) && hasFeature(feature)
    ? children
    : unauthorized;
};

type SwitchRedirect = RedirectProps & SwitchRedirectExtra;
export type SwitchChild<
  T extends Record<string, unknown> = Record<string, unknown>,
> = (SwitchRoute | SwitchRedirect) & T;

export default function ChildRoutes<
  T extends Record<string, unknown> = Record<string, unknown>,
>({
  routes,
  authorize,
}: {
  routes?: SwitchChild<T>[];
  authorize?: (args: T) => boolean;
}): JSX.Element | null {
  const { authorityMatches, hasFeature } = useAuth(
    ({ authorityMatches, hasFeature }) => ({ authorityMatches, hasFeature }),
  );

  if (!routes) {
    return null;
  }

  return (
    <Switch>
      {routes.map((route, i) => {
        const key = route.key || i;
        if (route.redirect === true) {
          return <Redirect key={key} {...route} />;
        }

        return (
          <Route
            key={key}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
          >
            <ErrorBoundary>
              <AuthorizeRoute
                route={route}
                authorize={authorize}
                authorityMatches={authorityMatches}
                hasFeature={hasFeature}
              >
                <Suspense fallback={<CenteredSpinner />}>
                  <route.Component />
                </Suspense>
              </AuthorizeRoute>
            </ErrorBoundary>
          </Route>
        );
      })}
      {/* Show a "Page not found" in last resort when there is no match in the switch */}
      <Route component={() => pageNotFound} />
    </Switch>
  );
}
