import "./helpers/initialize";
import "@/styles/applyStyles";

import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import { INTERCOM_APP_ID } from "@/shared/constants";
import history from "@/shared/history";
import { posthog } from "@/shared/posthog";

import ErrorBoundary from "@/components/Routing/ErrorBoundary";

import Main from "./Main";
import customizations from "@/shared/customizations";

const App = (): JSX.Element => {
  return (
    <ConfigProvider>
      <PostHogProvider client={posthog}>
        <IntercomProvider
          appId={INTERCOM_APP_ID ?? ""}
          shouldInitialize={customizations.enableIntercomSupport}
        >
          <ErrorBoundary>
            <Router history={history}>
              <Main />
            </Router>
          </ErrorBoundary>
        </IntercomProvider>
      </PostHogProvider>
    </ConfigProvider>
  );
};

export default Sentry.withProfiler(App);
