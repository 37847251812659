import {
  CalendarOutlined,
  CheckSquareOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MessageOutlined,
  NotificationOutlined,
  SolutionOutlined,
  StopOutlined,
  UserAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { MODEL_NAMES, NOTIFICATION_EVENTS } from "inexone-common/constants";
import type { InAppNotificationDataAttributes } from "inexone-common/models/InAppNotification";
import type * as ApiListNotifications from "inexone-common/types/apiFunctions/user/listNotifications";
import type * as ApiUserMarkAllAsRead from "inexone-common/types/apiFunctions/user/markAllAsRead";

import type { IconType } from "@/assets/icons";
import { cloudCode } from "@/shared/parseHelpers";

import type OrgMembership from "./OrgMembership";
import InexUser from "./User";
import ParseObject from "./utils/ParseObject";

const EVENT_NAME_ICONS: Record<string, IconType> = {
  [NOTIFICATION_EVENTS.BOOKING_COMPLETED]: CheckSquareOutlined,
  [NOTIFICATION_EVENTS.BOOKING_CALL_NOT_JOINED_WARNING]: InfoCircleOutlined,
  [NOTIFICATION_EVENTS.EXPERT_PROFILE_AVAILABILITY_PUBLISHED]: CalendarOutlined,
  [NOTIFICATION_EVENTS.BOOKING_CREATED]: CalendarOutlined,
  [NOTIFICATION_EVENTS.BOOKING_EDITED]: CalendarOutlined,
  [NOTIFICATION_EVENTS.BOOKING_CANCELED]: StopOutlined,
  [NOTIFICATION_EVENTS.EXPERT_PROFILE_PUBLISHED]: UserAddOutlined,
  [NOTIFICATION_EVENTS.THREAD_REQUEST_MESSAGE]: MessageOutlined,
  [NOTIFICATION_EVENTS.EXPERT_NOTE_MESSAGE]: SolutionOutlined,
  [NOTIFICATION_EVENTS.USER_CREATED]: MailOutlined,
  [NOTIFICATION_EVENTS.EXPERT_PROFILE_COMPLIANCE_REQUESTED]: WarningOutlined,
  [NOTIFICATION_EVENTS.EXPERT_PROFILE_COMPLIANCE_APPROVED]: CheckSquareOutlined,
  [NOTIFICATION_EVENTS.EXPERT_PROFILE_COMPLIANCE_DECLINED]: StopOutlined,
  default: NotificationOutlined,
};

type InAppNotificationAttributes = InAppNotificationDataAttributes & {
  recipient: InexUser;
  recipientMembership: OrgMembership;
};

export default class InAppNotification extends ParseObject<InAppNotificationAttributes>(
  MODEL_NAMES.IN_APP_NOTIFICATION,
) {
  isRead(): boolean {
    return Boolean(this.get("readAt"));
  }

  getIconComponent(): IconType {
    const eventName = this.eventName;

    if (!EVENT_NAME_ICONS[eventName]) {
      return EVENT_NAME_ICONS.default;
    }

    return EVENT_NAME_ICONS[eventName];
  }

  get eventName(): string {
    return this.get("payload").eventName;
  }

  get headline(): string {
    return this.get("payload").headline;
  }

  get textBody(): string {
    return this.get("payload").textBody;
  }

  get link(): string | undefined {
    return this.get("payload").link;
  }

  async markAsRead(): Promise<void> {
    if (!this.has("readAt")) {
      this.set("readAt", new Date());
      await this.save();
    }
  }

  /** See https://linear.app/inexone/issue/DEV-2176/inappnotification-investigate-potential-leak */
  isSelfRecipient(): boolean {
    return this.get("recipient").id === InexUser.current()?.id;
  }

  static listNotifications({
    page = 0,
    pageSize = 100,
    read = false,
  }: Partial<ApiListNotifications.Params>): Promise<ApiListNotifications.ReturnType> {
    return cloudCode("user_listNotifications", {
      page,
      pageSize,
      read,
    });
  }

  static notificationsButtonQuery(): Parse.Query<InAppNotification> {
    const currentUser = InexUser.current();
    const query = InAppNotification.query().doesNotExist("readAt");

    if (currentUser) {
      query.equalTo("recipient", currentUser);
    }
    return query;
  }

  static async markAllAsRead(): Promise<ApiUserMarkAllAsRead.ReturnType> {
    return cloudCode("user_markAllAsRead", {
      from: new Date(),
    });
  }
}
