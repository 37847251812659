// use zod directly to avoid cyclic dependencies with fVal
import * as z from "zod";

import { roundNumber } from "./numbers";

/**
 * Deprecating CNY and JPY, use AVAILABLE_CURRENCY instead if possible
 */
export enum Currency {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  CNY = "CNY",
  JPY = "JPY",
}

export const AVAILABLE_CURRENCY = {
  USD: Currency.USD,
  EUR: Currency.EUR,
  GBP: Currency.GBP,
} as const;

export type AvailableCurrency =
  (typeof AVAILABLE_CURRENCY)[keyof typeof AVAILABLE_CURRENCY];

/**
 * Currencies we actually want to show on the platform
 */
export const availableCurrencies = Object.values(AVAILABLE_CURRENCY);

// Make sure this is never used in a situation where it tries to validate on CNY / JPY
// Once ExchangeRateList is rid of CNY / JPY there's no danger.
export const validateOptionalCurrencyAmount = z
  .object({
    amount: z.number().optional(),
    currency: z.nativeEnum(AVAILABLE_CURRENCY),
  })
  .strict();

export const validateCurrencyAmount = validateOptionalCurrencyAmount.required();

export type CurrencyAmount = z.infer<typeof validateCurrencyAmount>;
export type OptionalCurrencyAmount = z.infer<
  typeof validateOptionalCurrencyAmount
>;

export const validateExchangeRateList = z
  .object({
    [Currency.USD]: z.number(),
    [Currency.EUR]: z.number(),
    [Currency.GBP]: z.number(),
    [Currency.CNY]: z.number(),
    [Currency.JPY]: z.number(),
  })
  .strict();

export type ExchangeRateList = z.infer<typeof validateExchangeRateList>;
export const currencySettings: Record<
  Currency,
  {
    label: string;
    inputPrecision: number;
    step: number;
    storePrecision: number;
    approximateUSD: number;
  }
> = {
  [Currency.USD]: {
    label: "US Dollars",
    inputPrecision: 0,
    storePrecision: 2,
    step: 50,
    approximateUSD: 1,
  },
  [Currency.EUR]: {
    label: "Euros",
    inputPrecision: 0,
    storePrecision: 2,
    step: 50,
    approximateUSD: 0.95,
  },
  [Currency.GBP]: {
    label: "British pounds",
    inputPrecision: 0,
    storePrecision: 2,
    step: 50,
    approximateUSD: 0.82,
  },
  [Currency.CNY]: {
    label: "Chinese yuan",
    inputPrecision: 0,
    storePrecision: 2,
    step: 250,
    approximateUSD: 6.69,
  },
  [Currency.JPY]: {
    label: "Japanese yen",
    inputPrecision: 0,
    // yen do not have cents
    storePrecision: 0,
    step: 5000,
    approximateUSD: 136,
  },
};

// TODO: here might consider some other way of rounding
export const roundCurrency = ({ amount, currency }: CurrencyAmount): number => {
  const { storePrecision } = currencySettings[currency];

  return roundNumber(amount, storePrecision);
};

export function displayCurrency(
  currencyAmount: OptionalCurrencyAmount | undefined,
  showDecimals: boolean,
  valueIfUndefined = "-",
): string {
  if (currencyAmount === undefined) {
    return valueIfUndefined;
  }

  const { currency, amount } = currencyAmount;

  if (amount === undefined) {
    return valueIfUndefined;
  }

  const { storePrecision } = currencySettings[currency];

  // Whether the number has decimals or not
  const needsDecimals = amount % 1 !== 0;

  const precision = showDecimals || needsDecimals ? storePrecision : 0;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    // The maximum fraction digits that we show is the precision of what we store
    maximumFractionDigits: storePrecision,
    // showDecimals is used to determine if we should show the minimum fraction digits
    minimumFractionDigits: precision,
  }).format(amount);
}

export function getCurrencySymbol(currency: AvailableCurrency): string {
  // NOTE: Leave en-US here, even when doing intl!
  // We rely on it to take the first element
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  const parts = formatter.formatToParts(0);
  const symbol = parts.find((part) => part.type === "currency")?.value;
  return symbol ?? currency;
}

export function createExchangeRateList(defaultValue: number): ExchangeRateList {
  return {
    [Currency.USD]: defaultValue,
    [Currency.EUR]: defaultValue,
    [Currency.GBP]: defaultValue,
    [Currency.CNY]: defaultValue,
    [Currency.JPY]: defaultValue,
  };
}

export function sumCurrencyAmounts(
  currencyAmounts: Array<OptionalCurrencyAmount | undefined>,
): ExchangeRateList {
  const sum = createExchangeRateList(0);

  currencyAmounts.forEach((currencyAmount) => {
    if (!currencyAmount) {
      return;
    }

    const { amount, currency } = currencyAmount;

    if (amount !== undefined) {
      sum[currency] += amount;
    }
  });

  return sum;
}

export function sumSingleCurrencyAmounts(
  currencyAmounts: Array<OptionalCurrencyAmount | undefined>,
): CurrencyAmount | undefined {
  let sum = 0;
  let sumCurrency: AvailableCurrency | undefined;

  currencyAmounts.forEach((currencyAmount) => {
    if (!currencyAmount) {
      return;
    }

    const { amount, currency } = currencyAmount;

    if (!sumCurrency) {
      sumCurrency = currency;
    }

    if (sumCurrency !== currency) {
      throw new Error("Currency mismatch");
    }

    if (amount !== undefined) {
      sum += amount;
    }
  });

  if (sumCurrency) {
    return { amount: sum, currency: sumCurrency };
  }

  return undefined;
}

export function getCurrencyAmount({
  exchangeRateList,
  currency,
}: {
  exchangeRateList: ExchangeRateList;
  currency: AvailableCurrency;
}): CurrencyAmount {
  const amount = exchangeRateList[currency];

  if (amount === undefined) {
    throw new Error("Unsupported currency (CNY or JPY)");
  }

  return { currency, amount };
}

export function getOptionalCurrencyAmount({
  exchangeRateList,
  currency,
}: {
  exchangeRateList?: ExchangeRateList;
  currency: AvailableCurrency;
}): OptionalCurrencyAmount {
  if (!exchangeRateList) {
    return { currency };
  }

  return getCurrencyAmount({ exchangeRateList, currency });
}

export function safeParseCurrencyAmount({
  amount,
  currency,
}: {
  amount: string | number;
  currency: string;
}): OptionalCurrencyAmount | undefined {
  const amountNum =
    typeof amount === "string" ? Number.parseInt(amount.trim(), 10) : amount;

  const value = validateOptionalCurrencyAmount.safeParse({
    amount: amountNum,
    currency: currency.trim().toUpperCase(),
  });

  if (value.success) {
    return value.data;
  }
}
