const USER_PREFIX = "/user";
const TEAM_PREFIX = "/team";
const SIGNED_IN_USER_PREFIX = "/signed-in-user";
const ADMIN_DASHBOARD_PREFIX = "/admin-dashboard";
const EN_DASHBOARD_PREFIX = "/en-dashboard";
const SURVEY_PROVIDER_DASHBOARD_PREFIX = "/survey-provider-dashboard";
const SURVEY_CLIENT_DASHBOARD_PREFIX = "/survey-client-dashboard";
const PROJECTS_PREFIX = "/projects" as const;
const PROJECTS_ID_PREFIX = `${PROJECTS_PREFIX}/:requestId` as const;
const PROJECTS_EDIT_PREFIX = `${PROJECTS_PREFIX}/edit/:requestId` as const;
const SURVEYS_PREFIX = "/surveys" as const;
const SURVEYS_ID_PREFIX = `${SURVEYS_PREFIX}/:surveyId` as const;
const SURVEYS_EDIT_PREFIX = `${SURVEYS_PREFIX}/edit/:surveyId` as const;

const PRINT_PREFIX = "/print";

const EXPERTS_PREFIX = `${PROJECTS_ID_PREFIX}/experts` as const;
const EXPERT_ID_PREFIX = `${EXPERTS_PREFIX}/:expertId` as const;

const DATABASE_PREFIX = "/database" as const;
const EXPERT_POOL_PREFIX = `${DATABASE_PREFIX}/expert-pool` as const;
const PROJECT_POOL_PREFIX = `${DATABASE_PREFIX}/project-pool` as const;

export enum EXPERT_PANEL_TABS {
  PROFILE = "profile",
  BOOKINGS = "bookings",
  COMPLIANCE = "compliance",
  NOTES = "notes",
  ATTACHMENTS = "attachments",
}

export const SURVEY_PATHS = {
  ROOT: SURVEYS_PREFIX,
  LIST: `${SURVEYS_PREFIX}/list/:view?`,
  EDIT: {
    ROOT: SURVEYS_EDIT_PREFIX,
    STEP1: `${SURVEYS_EDIT_PREFIX}/step1`,
    STEP2: `${SURVEYS_EDIT_PREFIX}/step2`,
    STEP3: `${SURVEYS_EDIT_PREFIX}/step3`,
    ADD_PROVIDERS: `${SURVEYS_EDIT_PREFIX}/add-providers`,
  },
  VIEW: {
    ROOT: SURVEYS_ID_PREFIX,
    OVERVIEW: `${SURVEYS_ID_PREFIX}/overview`,
    QUOTES: `${SURVEYS_ID_PREFIX}/quotes`,
    BILLING: `${SURVEYS_ID_PREFIX}/billing`,
    COMPARE: `${SURVEYS_ID_PREFIX}/:type(quote|final-charge)/compare/:quoteIds`,
    CHAT: `${SURVEYS_ID_PREFIX}/chat/:threadId?/:view?`,
    QUOTE_EDIT: `${SURVEYS_ID_PREFIX}/chat/:threadId?/quote/edit`,
    OLD_VIEW: `${SURVEYS_ID_PREFIX}/:threadId?/view`,
  },
} as const;

const PATHS = {
  /**
   * Paths related to the user layout: when someone is not logged in
   * Component: "layouts/UserLayout"
   */
  USER: {
    ROOT: USER_PREFIX,
    LOGIN: `${USER_PREFIX}/login`,
    BECOME: `${USER_PREFIX}/become/:token`,
    CREATE_ACCOUNT_EMAIL: `${USER_PREFIX}/create-account-email`,
    CREATE_ACCOUNT_PASSWORD: `${USER_PREFIX}/create-account-password`,
    ORGANIZATION_NOT_FOUND: `${USER_PREFIX}/organization-not-found`,
    CHOOSE_PASSWORD: `${USER_PREFIX}/choose-password`,
    FORGOT_PASSWORD: `${USER_PREFIX}/forgot-password`,
    VERIFY_EMAIL_RESET: `${USER_PREFIX}/verify-email-reset`,
    ACCOUNT_ALREADY_EXISTS: `${USER_PREFIX}/account-already-exists`,
  },

  CALLS: "/calls/:bookingId/:pinCode",

  /** status: success | error | undefined */
  VERIFY_EMAIL_COMPLETE: `/verify-email-complete/:status?`,

  SIGNED_IN_USER: {
    ROOT: SIGNED_IN_USER_PREFIX,
    VERIFY_EMAIL: `${SIGNED_IN_USER_PREFIX}/verify-email`,
    COMPLETE_PROFILE: `${SIGNED_IN_USER_PREFIX}/complete-profile`,
    ACCEPT_POLICY: `${SIGNED_IN_USER_PREFIX}/accept-policy`,
  },

  /**
   * Paths related to the default layout:
   * Component: "layouts/DefaultLayout"
   */
  DEFAULT: {
    ROOT: "/",
    NOTIFICATIONS: "/notifications",
    PROFILE: "/profile",
    TEAM: {
      ROOT: TEAM_PREFIX,
      LEADERBOARD: `${TEAM_PREFIX}/leaderboard`,
      MANAGEMENT: `${TEAM_PREFIX}/management`,
      MY_STATS: `${TEAM_PREFIX}/my-stats`,
    },
    PRINT: {
      ROOT: PRINT_PREFIX,
      EXPERTS: `${PRINT_PREFIX}/experts`,
    },
    MY_STATS: "/my-stats",
    KNOWLEDGE: "/knowledge/:expertId?",
    CALL_MONITOR: {
      ROOT: `/call-monitor/:view?/:subView?`,
    },
    ADMIN_DASHBOARD: {
      ROOT: ADMIN_DASHBOARD_PREFIX,
      COMPLETED_CALLS: `${ADMIN_DASHBOARD_PREFIX}/completed-calls`,
      BOOKED_CALLS: `${ADMIN_DASHBOARD_PREFIX}/booked-calls`,
      ORG_BILLING_DETAILS: `${ADMIN_DASHBOARD_PREFIX}/org-billing-details`,
    },
    EN_DASHBOARD: {
      ROOT: EN_DASHBOARD_PREFIX,
      COMPLETED_CALLS: `${EN_DASHBOARD_PREFIX}/completed-calls`,
      BOOKED_CALLS: `${EN_DASHBOARD_PREFIX}/booked-calls`,
      PROJECTS: `${EN_DASHBOARD_PREFIX}/projects/:projectView?`,
      COMPANY_DASHBOARD: `${EN_DASHBOARD_PREFIX}/company-dashboard`,
      TEAM_DASHBOARD: `${EN_DASHBOARD_PREFIX}/team-dashboard`,
    },
    SURVEY_PROVIDER_DASHBOARD: {
      ROOT: SURVEY_PROVIDER_DASHBOARD_PREFIX,
      PROJECTS: `${SURVEY_PROVIDER_DASHBOARD_PREFIX}/projects/:surveyView?`,
      FINAL_CHARGES: `${SURVEY_PROVIDER_DASHBOARD_PREFIX}/final-charges/:surveyInvoiceView?`,
      PERFORMANCE: `${SURVEY_PROVIDER_DASHBOARD_PREFIX}/performance`,
    },
    SURVEY_CLIENT_DASHBOARD: {
      ROOT: SURVEY_CLIENT_DASHBOARD_PREFIX,
      FINAL_CHARGES: `${SURVEY_CLIENT_DASHBOARD_PREFIX}/final-charges/:surveyInvoiceView?`,
      BILLING: `${SURVEY_CLIENT_DASHBOARD_PREFIX}/billing`,
    },
    BILLING: {
      ROOT: "/billing",
    },

    DATABASE: {
      ROOT: DATABASE_PREFIX,
      EXPERT_POOL: {
        ROOT: `${EXPERT_POOL_PREFIX}/:expertCvId?`,
        EDIT: `${EXPERT_POOL_PREFIX}/:expertCvId/edit`,
      },
      PROJECT_POOL: {
        ROOT: PROJECT_POOL_PREFIX,
      },
    },
    REQUESTS: {
      ROOT: PROJECTS_PREFIX,
      LIST: `${PROJECTS_PREFIX}/list/:view?`,
      EN_CREATE: `${PROJECTS_PREFIX}/en_create`,
      CREATE: `${PROJECTS_PREFIX}/create`,
      EDIT: {
        ROOT: PROJECTS_EDIT_PREFIX,
        BRIEF: `${PROJECTS_EDIT_PREFIX}/brief`,
        ADD_NETWORKS: `${PROJECTS_EDIT_PREFIX}/add-networks`,
        PUBLISHED: `${PROJECTS_EDIT_PREFIX}/published`,
      },
      REQUEST: {
        ROOT: PROJECTS_ID_PREFIX,
        EXPERTS: {
          ROOT: `${EXPERTS_PREFIX}/:expertId?`,
          EXPERTS_NEW: `${EXPERTS_PREFIX}/new`,
          EXPERT: {
            ROOT: EXPERT_ID_PREFIX,
            PROFILE: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.PROFILE}`,
            BOOKINGS: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.BOOKINGS}`,
            COMPLIANCE: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.COMPLIANCE}`,
            NOTES: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.NOTES}`,
            // EN_NOTES: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.EN_NOTES}`,
            ATTACHMENTS: `${EXPERT_ID_PREFIX}/${EXPERT_PANEL_TABS.ATTACHMENTS}`,
            EDIT: `${EXPERT_ID_PREFIX}/edit`,
            EDIT_CV: `${EXPERT_ID_PREFIX}/edit-cv/:expertCvId`,
          },
        },
        OVERVIEW: `${PROJECTS_ID_PREFIX}/overview`,
        CHAT: `${PROJECTS_ID_PREFIX}/chat/:organizationId?`,
        CALENDAR: `${PROJECTS_ID_PREFIX}/calendar/:pageMode?`,
        BILLING: `${PROJECTS_ID_PREFIX}/billing`,
        TRANSCRIPTS: {
          ROOT: `${PROJECTS_ID_PREFIX}/transcripts/:transcriptId?`,
        },
        INSIGHTS: {
          ROOT: `${PROJECTS_ID_PREFIX}/insights`,
        },
      },
    },
    SURVEYS: SURVEY_PATHS,
  },
} as const;

export const EXPERT_BASE_PATH = PATHS.DEFAULT.REQUESTS.REQUEST.EXPERTS.EXPERT;
export const TRANSCRIPT_BASE_PATH = PATHS.DEFAULT.REQUESTS.REQUEST.TRANSCRIPTS;

export const IGNORE_USER_PATHS = [PATHS.CALLS, PATHS.VERIFY_EMAIL_COMPLETE];

/** Backend paths that we want the frontend to be aware of */
export const BACK_END_PATHS = {
  SSO_CHECK: "/sso/check",
  SSO_AUTH: "/sso/auth",
  SSO_CALLBACK: "/sso/callback",
} as const;

/**
 * Query parameters that can be passed to the become endpoint,
 * for special integrations with the backend
 */
export enum BECOME_QUERY_PARAMS {
  /** Project to create after profile is completed */
  PROJECT_AFTER_COMPLETE = "projectAfterComplete",
  /** URL to redirect to after log in is successful */
  REDIRECT = "redirect",
}

export default PATHS;
