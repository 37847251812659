import { notification } from "antd";
import moment from "moment";

import initParse from "@/helpers/initParse";
import { applicationId, parseServerUrl } from "@/shared/parseConfig";

import checkFrontendVersion from "@/pages/root-pages/helpers/checkFrontendVersion";

initParse(applicationId, parseServerUrl);

moment.updateLocale("en", {
  week: {
    dow: 1,
    doy: 4,
  },
});

/**
 * This runs once, after the page successfully loads
 */
window.addEventListener("load", () => {
  void checkFrontendVersion();
});

/**
 * This runs every time focus goes back to the page
 */
window.addEventListener("focus", () => {
  void checkFrontendVersion();
});

/**
 * Initialization of all notification popups
 */
notification.config({
  placement: "topRight",
  top: 48, // top menu height
});
