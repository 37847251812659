import { USER_ROLE_NAMES } from "./userRoleNames";

/**
 * the types of messages that can show up in a thread
 */
export enum THREAD_MESSAGE_TYPES {
  /** Simple text message sent by the user */
  USER_TEXT_MESSAGE = "userTextMessage",
  /** Text message generated by the back-end */
  TEXT_NOTIFICATION = "textNotification",
}

export const enSideMap: Record<USER_ROLE_NAMES, { label: string }> = {
  [USER_ROLE_NAMES.CUSTOMER]: {
    label: "Client",
  },
  [USER_ROLE_NAMES.CONTRACTOR]: {
    label: "Network",
  },
};

export const surveySideMap: Record<USER_ROLE_NAMES, { label: string }> = {
  [USER_ROLE_NAMES.CUSTOMER]: {
    label: "Client",
  },
  [USER_ROLE_NAMES.CONTRACTOR]: {
    label: "Provider",
  },
};

export enum DISPLAY_ROW_OPTIONS {
  FEW = "FEW",
  MANY = "MANY",
  ALL = "ALL",
}
