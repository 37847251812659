import * as Sentry from "@sentry/react";

import history from "@/shared/history";

/**
 * Possible values for the env:
 * master
 * pull/1191/head
 */
const branchName = (import.meta.env.VITE_CURRENT_BRANCH || "").replace(
  /\//g,
  "-",
);

const environment = import.meta.env.VITE_ENVIRONMENT;

export default function initSentry(): void {
  /**
   * To add release tracking,
   * we should interpolate our HTML file index.html with the value, so that
   * it doesn't pollute all our JS chunks
   */
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment,
    initialScope: {
      tags: { branch: branchName },
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
      "Impersonator operation ignored",
      "Session token is expired",
      "Invalid session token",
      "Object captured as promise rejection with keys",
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException;

      /** Grouping every "Loading chunk" error together */
      if (error instanceof Error) {
        if (error.message.startsWith("Loading chunk")) {
          event.fingerprint = ["chunk-load-failure"];
        }
      }

      return event;
    },
  });
}
