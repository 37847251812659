import { isObject as _isObject, trim as _trim } from "lodash";
import moment from "moment-timezone";
import sanitizeHtml from "sanitize-html";
import * as z from "zod";

import {
  BOOKING_STATES,
  EXPERT_PROFILE_STATES,
  FILE_TYPES,
  PARSE_OBJECT_ID_LENGTH,
  SANITIZE_HTML_OPTIONS,
  TEXT_AREA_MAX_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
} from "inexone-common/constants";
import { ImageValueSchema } from "inexone-common/definitions/Image";
import type {
  AvailableModelNames,
  AvailableModels,
  InstanceOfModel,
} from "inexone-common/types/models";
import {
  AVAILABLE_CURRENCY,
  validateExchangeRateList,
} from "inexone-common/utils/currency";
import type { PartialRecord } from "inexone-common/utils/typesafe";

import { supportedLanguages } from "../constants/transcriptLanguages";
import { patterns } from "./validate/basic";

export * from "zod";

export type shapeToType<T extends z.ZodRawShape> = z.infer<z.ZodObject<T>>;
export type shapeToInputType<T extends z.ZodRawShape> = z.input<z.ZodObject<T>>;

export const richTextArea = () =>
  z
    .string()
    .refine((value) => value.length <= TEXT_AREA_MAX_LENGTH, {
      message: "Input of rich text area is too long",
    })
    .transform((value): string => sanitizeHtml(value, SANITIZE_HTML_OPTIONS));

export const textArea = () =>
  z
    .string()
    .transform((value): string => value.substring(0, TEXT_AREA_MAX_LENGTH));

/**
 * A slug that is valid for use in a URL
 */
export const subdomainSlug = () =>
  z.string().refine((slug) => {
    try {
      const url = new URL(`https://${slug}.inex.one`);
      const subdomainPart = url.hostname.split(".")[0];
      /**
       * This checks for special characters, spaces and "." in the subdomain
       */
      return subdomainPart === slug;
    } catch (_error) {
      return false;
    }
  }, "Invalid slug");

export const urlWithSlug = () =>
  z
    .string()
    .url()
    .pipe(
      z
        .string()
        .url()
        .refine(
          (value) => new URL(value).host.split(".").length >= 3,
          "The URL doesn't contain a subdomain part",
        ),
    )
    .transform((value) => new URL(value).host.split(".")[0])
    .pipe(subdomainSlug());

export const textField = () =>
  z
    .string()
    .transform((value): string =>
      _trim(value.substring(0, TEXT_FIELD_MAX_LENGTH)),
    );

export const parseId = () =>
  z
    .string()
    .length(PARSE_OBJECT_ID_LENGTH, "Invalid parseId length")
    .regex(/^[A-Za-z0-9]+$/, "ParseId contains invalid characters");

export const pinCode = () =>
  z
    .string()
    .length(6, "PinCode must be 6 characters")
    .regex(/^[0-9]+$/, "PinCode can only contain numbers");

/**
 * If the value is null, we make it undefined instead
 */
export const nullableToUndefined = <T extends z.ZodTypeAny>(zodType: T) =>
  zodType
    .optional()
    .nullable()
    .transform((val) => val ?? undefined);

/**
 * If the value is null or undefined, we make it null instead
 */
export const optionalToNullable = <T extends z.ZodTypeAny>(zodType: T) =>
  zodType
    .optional()
    .nullable()
    .transform((val) => val ?? null);

/**
 * When querying the db directly dates in objects and arrays may be stored in a funky format
 * { __type: "date", iso: <dateString> } this checks that and converts to a regular date
 */
export const isoDate = () =>
  z
    .object({
      __type: z.string(),
      iso: z.string(),
    })
    .refine(
      (dateObject) => {
        if (dateObject.__type !== "Date") return false;

        const date = new Date(dateObject.iso);

        return date && date.toISOString() === dateObject.iso;
      },
      { message: "Iso date is invalid" },
    )
    .transform(({ iso }) => new Date(iso));

export const dateRange_dbSafe = () =>
  z
    .tuple([isoDate(), isoDate().nullable()])
    .refine(
      ([date1, date2]) =>
        date2 !== null ? date1.getTime() <= date2.getTime() : true,
      { message: "Date range is invalid" },
    );

export const dateRange = () =>
  z
    .tuple([z.date(), z.date().nullable()])
    .refine(
      ([date1, date2]) =>
        date2 !== null ? date1.getTime() <= date2.getTime() : true,
      { message: "Date range is invalid" },
    );

export const baseCalendarEvent = z.object({
  start: z.date(),
  end: z.date(),
});

export const email = () =>
  z
    .string({ required_error: "Please add an email address" })
    .trim()
    .email({
      message: "Please enter a valid email address",
    })
    .transform((value) => value.toLowerCase());

/** An array of domains */
export const domains = () =>
  z.array(
    z
      .string()
      .regex(patterns.domainName, "Invalid domain name")
      .transform((value) => value.toLowerCase()),
  );

export const fqdn = () => z.string().regex(patterns.fqdn);

export const currency = () => z.nativeEnum(AVAILABLE_CURRENCY);

export const timeIntervalUnit = () => z.enum(["day", "month", "year"]);

export const timestampInterval = () =>
  z.tuple([z.number().min(0), z.number().min(0)]);

export const bookingState = () => z.nativeEnum(BOOKING_STATES);

export const expertState = () => z.nativeEnum(EXPERT_PROFILE_STATES);
export const parseObject = <T extends AvailableModels>(Class: T) =>
  z.instanceof<T>(Class);

export const baseParseAttributes = z.object({
  id: parseId(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

/**
 * Instead of needing a runtime Parse to perform the checks, we perform a simpler
 * validation with a type guard, only checking for the constructor name
 */
const instanceTypeGuard =
  <T>(constructorName: string) =>
  (unknownInstance: unknown): unknownInstance is T =>
    typeof unknownInstance === "object" &&
    unknownInstance?.constructor?.name === constructorName;

/** Lightweight checks for object instances */

/** Lightweight checks for object instances */
const isParseFile = instanceTypeGuard<Parse.File>("ParseFile");
/** Lightweight checks for object instances */
const isParseRelation = instanceTypeGuard<Parse.Relation>("ParseRelation");

const hasProperty = <U extends object | null, T extends string>(
  value: U,
  property: T,
): value is U & Record<T, unknown> => {
  return typeof value === "object" && value && property in value;
};

export const isParseObject = (
  unknownInstance: unknown,
): unknownInstance is Parse.Object =>
  typeof unknownInstance === "object" &&
  hasProperty(unknownInstance, "className") &&
  typeof unknownInstance.className === "string" &&
  hasProperty(unknownInstance, "attributes") &&
  typeof unknownInstance.attributes === "object";
// for cases when we dont want to include the model to test if its an instance (in the models)
// this provides a slightly less safe check
export const parseObjectRef = <T extends AvailableModelNames>(className: T) =>
  z.custom<InstanceOfModel<T>>(
    (value) =>
      isParseObject(value) &&
      className === value.className &&
      Boolean(value.id),
    (value) => ({
      message: !isParseObject(value)
        ? `Provided object isn't instance of Parse.Object. ${JSON.stringify(
            value,
          )} received`
        : className !== value.className
          ? `Provided object has class name ${value?.className} instead of ${className}`
          : "Provided object does not have id",
    }),
  );

export const loadedParseObject = <T extends AvailableModels>(Class: T) =>
  z
    .instanceof<T>(Class)
    .refine((value: Parse.Object) => value.isDataAvailable(), {
      message: `Instance of class ${Class.prototype.className} is not loaded`,
    });

export const parseRelation = <T extends AvailableModelNames>(
  targetModelName: T,
) =>
  z.custom<Parse.Relation<Parse.Object, InstanceOfModel<T>>>(
    (probablyRelation) =>
      probablyRelation === undefined ||
      (isParseRelation(probablyRelation) &&
        probablyRelation.targetClassName === targetModelName),
  );

export const parseFile = () =>
  z.custom<Parse.File>(isParseFile, {
    message: "Input not instance of ParseFile",
  });

export const exchangeRateList = () => validateExchangeRateList;

export const exchangeRateDate = () =>
  z
    .string()
    .length(10)
    .refine((value) => {
      return moment(value, "YYYY-MM-DD").isValid();
    });

export const name = () => z.string().trim();

export const timezone = () =>
  z.string().refine(
    (timezone) => moment.tz.zone(timezone) != null,
    (val: string) => ({ message: `Invalid timezone. ${val} received` }),
  );

export const url = () =>
  z
    .string()
    .regex(patterns.url, "Invalid Url")
    .transform((value: string) =>
      _trim(value.substring(0, TEXT_FIELD_MAX_LENGTH)),
    );

export const notification = z.object({
  count: z.number(),
  disabled: z.boolean(),
  timestamp: z.date(),
});

export const notificationCountersCategory = z.record(z.string(), notification);

export const imageValue = () => ImageValueSchema;

export const recordingLanguage = () => z.enum(supportedLanguages);

export const parseNumber = (
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
) => z.number().min(min).max(max);

export const ExpertProfileIdSchema = parseId().or(
  z.object({
    projectId: parseId(),
    expertCVId: parseId(),
  }),
);
export type ExpertProfileIdSchema = z.infer<typeof ExpertProfileIdSchema>;

export const dateOrDateString = () =>
  z.preprocess((arg) => {
    if (typeof arg === "string" || arg instanceof Date) {
      return new Date(arg);
    }
    return arg;
  }, z.date());

export const fileType = () => z.nativeEnum(FILE_TYPES);

export const recordWithEnumKeys = <
  Keys extends string[],
  ValuesSchema extends z.ZodSchema<unknown>,
>(
  keys: Keys,
  values: ValuesSchema,
) =>
  z.custom<PartialRecord<Keys[number], z.infer<ValuesSchema>>>(
    <T>(value: T) =>
      _isObject(value) &&
      Object.keys(value).every(
        (role) =>
          keys.includes(role) &&
          _isObject(value) &&
          values.safeParse(value[role as keyof T]).success,
      ),
    (value) => ({
      message: !_isObject(value)
        ? "Value is not object"
        : Object.keys(value).filter((role) => !keys.includes(role)).length > 0
          ? "Role name value is not valid"
          : "Value of role is not valid",
    }),
  );
export const digestPayload = () =>
  z.object({
    senderMembershipId: parseId().optional(),
  });

export const questionItemFormValue = z.object({
  id: z.string().optional(),
  question: z.string().optional(),
});

export const vettingQuestionFormValues = z.array(questionItemFormValue);

export const requestAngleFormValue = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  formQuestions: vettingQuestionFormValues.optional(),
  expectedCalls: z.number().optional(),
});

/**
 * When dealing with forms, values could be "null" or empty strings.
 * In this case, consider the value as undefined
 */
export const formSafeSchema = <T extends z.ZodSchema>(schema: T) =>
  z.preprocess((arg) => (arg === "" || arg === null ? undefined : arg), schema);

/** Out of a shape, transform every schema so that they're preprocessed to be form safe */
export const formSafeObject = <T extends z.ZodRawShape>(shape: T) =>
  z.object(
    Object.fromEntries(
      Object.entries(shape).map(([key, schema]) => [
        key,
        formSafeSchema(schema),
      ]),
    ),
  ) as unknown as z.ZodObject<T>;

// Heavily inspired by the original implementation of `.partial()` in zod
// https://github.com/colinhacks/zod/blob/59768246aa57133184b2cf3f7c2a1ba5c3ab08c3/src/types.ts#L1897
export const nullify = <T extends z.ZodRawShape>(
  zodObject: z.ZodObject<T>,
): z.ZodObject<{ [key in keyof T]: z.ZodOptional<T[key]> }> => {
  // biome-ignore lint/suspicious/noExplicitAny: eslint migration
  const newShape: any = {};

  for (const key in zodObject.shape) {
    const fieldSchema = zodObject.shape[key];
    newShape[key] = formSafeSchema(fieldSchema);
  }

  return new z.ZodObject({
    ...zodObject._def,
    shape: () => newShape,
  });
};
