import customLogos, { type LogoVersions } from "@/assets/logos/custom";
import philipSrc from "@/assets/team/philip.jpg";
import yevgenSrc from "@/assets/team/yevgen.png";
import abhishekSrc from "@/assets/team/abhishek.jpg";
import chrisContinentalExchangeSrc from "@/assets/team/continentalexchange_chris.jpeg";
import mattanSrc from "@/assets/team/mattan.png";
import NewEmptyProject from "@/assets/illustrations/empty-project.svg?react";
import OldEmptyProject from "@/assets/illustrations/clipboard-project.svg?react";

/**
 * Dictionary of customizations based on the slug of the app.
 */

type Settings = {
  /** The name of the service, e.g. "Inex One" */
  name: string;
  /** Optional custom favicon URL */
  favicon: string | undefined;
  /** The height of the logo, for those that are more squared */
  logoHeight: "standard" | "large";
  /** Show the left panel in the auth */
  authShowLeftPanel: boolean;
  /** Show the CTA in the auth */
  authShowCTA: boolean;
  /** Suffix in the page title. If absent from config and env, this will be "name" */
  pageTitle: string | undefined;
  /** Is it a demo environment, with a different behaviour */
  isDemo: boolean;
  /** If present, there will be a header banner with this text */
  headerBanner: string | undefined;
  /** A set of custom logos */
  logos: LogoVersions | undefined;
  /** A custom description in the login page */
  loginDescription: string | undefined;
  /** Disable subdomains for the app */
  disableSubdomains: boolean;
  /** Hide the description in the project list empty state */
  hideProjectListEmptyStateDescription: boolean;
  /** Hide the stats page */
  hideMyStats: boolean;
  /** Hide the name in the login page */
  hideNameOnLogin: boolean;
  /** Alert message for the billing details page */
  billingDetailsAlert: string | undefined;
  /** Hide the transcript empty state description */
  hideTranscriptEmptyStateDescription: boolean;
  /** Contact info for creating an account */
  createAccountContactInfo: {
    name: string;
    position: string;
    email: string;
    phone: string;
    img: string;
  };
  /** Enable Intercom support in the app */
  enableIntercomSupport: boolean;
  emptyExpertListCopy: string;
  /* Third bullet point in You project is still live modal
   * frontend/src/pages/requests/list/components/modals/CloseProjectReminderModal.tsx
   */
  projectStillLiveBulletPoint: string;
  /* React component used for empty state image on RequestList */
  emptyProjectImage: React.FunctionComponent;
};

export const defaultSettings: Settings = {
  name: "Inex One",
  favicon: "/favicon.ico",
  logoHeight: "standard",
  authShowLeftPanel: true,
  loginDescription:
    "The world's best expert networks and survey providers - in one dashboard.",
  authShowCTA: true,
  hideProjectListEmptyStateDescription: false,
  hideMyStats: false,
  hideNameOnLogin: false,
  billingDetailsAlert:
    "To add or edit your billing details, please contact Inex One.",
  hideTranscriptEmptyStateDescription: false,
  createAccountContactInfo: {
    name: "Philip Ståhl Hansen",
    email: "philip@inex.one",
    phone: "+46 (0) 70 754 88 83",
    position: "Chief Revenue Officer",
    img: philipSrc,
  },
  disableSubdomains: false,
  pageTitle: undefined,
  isDemo: false,
  headerBanner: undefined,
  logos: undefined,
  enableIntercomSupport: true,
  emptyExpertListCopy:
    "The Expert Networks are searching the globe for your experts. Chat with them to check on their progress!",
  projectStillLiveBulletPoint:
    "Send a message to the expert networks with feedback or new information",
  emptyProjectImage: NewEmptyProject,
};

const whiteLabelCustomizations = {
  pageTitle: undefined,
  isDemo: false,
  authShowCTA: true,
  authShowLeftPanel: false,
  loginDescription: "Full service expert calls, all in one platform",
  disableSubdomains: true,
  hideProjectListEmptyStateDescription: true,
  hideMyStats: true,
  hideNameOnLogin: true,
  billingDetailsAlert: "To add or edit your billing details, please contact us",
  hideTranscriptEmptyStateDescription: true,
  enableIntercomSupport: false,
  emptyExpertListCopy:
    "We are searching the globe for your experts. Chat with us to check on our progress!",
  projectStillLiveBulletPoint:
    "Send a message with feedback or new information",
  emptyProjectImage: OldEmptyProject,
  headerBanner: undefined,
} as const satisfies Partial<Settings>;

const demoCustomizations = {
  headerBanner:
    "All data on this site is fictive, and only used for illustrative purposes. Data is refreshed every day.",
  isDemo: true,
} as const satisfies Partial<Settings>;

const focalFactCustomizations: Settings = {
  ...whiteLabelCustomizations,
  name: "Focal Fact",
  favicon: customLogos.focalfact.square_dark,
  logoHeight: "large",
  logos: customLogos.focalfact,
  createAccountContactInfo: {
    name: "Yevgen Shatalov",
    email: "yevgen@focalfact.com",
    img: yevgenSrc,
    phone: "+353 85 758 0400",
    position: "Chief Executive Officer",
  },
};

const theContinentalExchangeCustomizations: Settings = {
  ...whiteLabelCustomizations,
  name: "The Continental Exchange",
  favicon: customLogos.continentalexchange.square_dark,
  logoHeight: "large",
  logos: customLogos.continentalexchange,
  createAccountContactInfo: {
    name: "Chris Leach",
    email: "cleach@thecontinental.exchange",
    img: chrisContinentalExchangeSrc,
    phone: "+1 214 444 3022",
    position: "Founder & Managing Partner",
  },
};

const vedakCustomizations: Settings = {
  ...whiteLabelCustomizations,
  name: "Vedak",
  favicon: customLogos.vedak.square_dark,
  logoHeight: "large",
  logos: customLogos.vedak,
  createAccountContactInfo: {
    name: "Abhishek Shinde",
    email: "abhishek@vedak.com",
    img: abhishekSrc,
    phone: "+91 980 480 1141",
    position: "Founder",
  },
};

const talismanInsightCustomizations: Settings = {
  ...whiteLabelCustomizations,
  name: "Talisman Insight",
  favicon: customLogos.talismaninsight.square_dark,
  logoHeight: "large",
  logos: customLogos.talismaninsight,
  createAccountContactInfo: {
    name: "Mattan Babajanian",
    email: "mattan@talismaninsight.com",
    img: mattanSrc,
    phone: "+1 516 864 6551",
    position: "Director",
  },
};

const customizations: Record<string, Partial<Settings>> = {
  enco: {
    name: "Enco Insights",
    favicon: customLogos.enco.square_dark,
    logoHeight: "large",
    authShowCTA: false,
    authShowLeftPanel: false,
    pageTitle: "Enco Insights",
    logos: customLogos.enco,
  },
  northerninsights: {
    name: "Northern Insights",
    favicon: customLogos.northerninsights.square_dark,
    logoHeight: "large",
    authShowCTA: false,
    authShowLeftPanel: false,
    pageTitle: "Northern Insights",
    logos: customLogos.northerninsights,
  },
  "focal-fact": focalFactCustomizations,
  thecontinentalexchange: theContinentalExchangeCustomizations,
  vedak: vedakCustomizations,
  talismaninsight: talismanInsightCustomizations,

  // Client prod domains
  "project.focalfact.com": focalFactCustomizations,
  "platform.thecontinental.exchange": theContinentalExchangeCustomizations,
  "project.vedak.com": vedakCustomizations,
  "platform.talismaninsight.com": talismanInsightCustomizations,

  // Client demo domains
  "demo.project.focalfact.com": {
    ...focalFactCustomizations,
    pageTitle: "[DEMO] Focal Fact",
    ...demoCustomizations,
  },
  "demo.platform.thecontinental.exchange": {
    ...theContinentalExchangeCustomizations,
    pageTitle: "[DEMO] The Continental Exchange",
    ...demoCustomizations,
  },
  "demo.platform.talismaninsight.com": {
    ...talismanInsightCustomizations,
    pageTitle: "[DEMO] Talisman Insight",
    ...demoCustomizations,
  },

  // Inex demo envs
  "talismaninsight-demo": {
    ...talismanInsightCustomizations,
    pageTitle: "[DEMO] Talisman Insight",
    ...demoCustomizations,
  },
  "thecontinentalexchange-demo": {
    ...theContinentalExchangeCustomizations,
    pageTitle: "[DEMO] The Continental Exchange",
    ...demoCustomizations,
  },
  "focal-fact-demo": {
    ...focalFactCustomizations,
    pageTitle: "[DEMO] Focal Fact",
    ...demoCustomizations,
  },
  demo: {
    pageTitle: "[DEMO] Inex One",
    ...demoCustomizations,
  },
};

const settings = {
  ...defaultSettings,
  ...customizations[window.location.hostname.split(".")[0]],
  ...customizations[window.location.hostname],
  ...customizations[import.meta.env.VITE_CUSTOM_SLUG ?? ""],
};
export default settings;
