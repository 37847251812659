/**
 * The formats for momentjs.
 */
enum MOMENT_FORMATS {
  // GENERAL
  DATE = "D MMM YYYY",
  MONTH_YEAR = "MMM YYYY",
  TIME_DATE = "HH:mm, D MMM YYYY",

  /**
   * This format is used to represent HH:mm.
   * @example 15:37
   */
  TIME = "HH:mm",

  /**
   * This format is used to represent HH:mm:ss.
   * @example 15:37:23
   */
  TIMESTAMP = "HH:mm:ss",
}

export default MOMENT_FORMATS;
